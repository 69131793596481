define([
  'jquery',
  'underscore',
  'backbone',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/orderMemoryPopup.hbs',

  'modules/admin/behaviors/loader',
  'modules/common/components/locale',
  'modules/common/components/currency',

  'modules/shop.cash-register-retail/views/popups/orderMemoryPopup/list',
  'modules/shop.cash-register-retail/collections/orderMemory',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/shop.cash-register-retail/views/popups/confirmPopup',

  'modules/shop.cash-register-retail/models/selectedCustomer',
  'modules/shop.cash-register-retail/collections/currentOrderItem',
  'modules/shop.cash-register-retail/collections/currentPaymentMethodItem',
], (
  $, _, Backbone, PopupView, Template,
  Loader, Locale, Currency,
  ListView, OrderMemoryCollection, CashRegisterApi, ConfirmPopup,
  SelectedCustomerModel, OrderItemCollection, PaymentMethodItemCollection,
) => PopupView.extend({
  template: Template,

  className: 'dialog order-memory-popup',

  ui: {
    close: '[data-action="close"]',
    clearAll: '[data-action="clear-all"]',
    newOrder: '[data-action="new-order"]',
  },

  events: {
    'click @ui.close': 'closeClicked',
    'touchend @ui.close': 'closeClicked',
    'click @ui.clearAll': 'clearAllClicked',
    'click @ui.newOrder': 'newOrderClicked',
  },

  regions: {
    popup: '[data-region=popupInPopup]',
    list: {
      selector: '[data-region="list"]',
      // needed to not end up with extra div inside table
      regionClass: Backbone.Marionette.Region.extend({
        attachHtml(view) {
          this.el.appendChild(view.el);
        },
      }),
    },
  },

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  initialize(options) {
    PopupView.prototype.initialize.call(this, options);
  },

  open(def) {
    def = this.renderInFloatingRegion(def);
    OrderMemoryCollection.clearOlderThen24h();

    this.closeDef = def;

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/orderMemoryPopup',
    });

    this.openPopup();

    return def;
  },

  clearAllClicked() {
    CashRegisterApi.logAction('MEMORY_BUTTON_CLICKED', {
      button: 'clear',
    });

    const def = new $.Deferred();

    def.done(() => {
      OrderMemoryCollection.clear();
    });

    const view = new ConfirmPopup();
    view.open(
      Locale.translate('are_you_sure_you_want_to_clear_saved_orders_question'),
      null,
      def,
    );
  },

  newOrderClicked() {
    CashRegisterApi.logAction('MEMORY_BUTTON_CLICKED', {
      button: 'new',
    });

    const def = new $.Deferred();

    def.done(() => {
      PaymentMethodItemCollection.clear();
      OrderItemCollection.clear();
      SelectedCustomerModel.unload();
    });

    const view = new ConfirmPopup();
    view.open(
      Locale.translate('are_you_sure_you_want_to_start_a_new_order_question'),
      null,
      def,
    );
  },

  onRender() {
    this.renderOrderList();
  },

  renderOrderList() {
    const region = this.getRegion('list');
    region.show(new ListView({
      collection: OrderMemoryCollection,
      popupView: this,
    }));
  },

}));
