define([
  'jquery',
  'underscore',
  'backbone',
  './item/swappable',
  'modules/common/collections/DeepModelCollection',
], (
  $, _, Backbone, ProductView, DeepModelCollection,
) => Backbone.Marionette.CollectionView.extend({

  childView: ProductView,

  className: 'sum',

  initialize(options) {
    this.collection = new DeepModelCollection();
    this.updateCollection();
  },

  onShow() {
    this.collection.on('change remove', this.updateModel, this);
    this.model.on('order_item_added', this.updateCollection, this);
  },

  onDestroy() {
    this.collection.off('change remove', this.updateModel, this);
    this.model.off('order_item_added', this.updateCollection, this);
  },

  updateModel() {
    this.model.unset('order.order_items', { silent: true });
    this.model.set({
      'order.order_items': this.collection.toJSON(),
    });
    this.model.calculateEndDate();
  },

  updateCollection() {
    this.collection.reset(
      this.model.get('order.order_items'),
    );
  },

}));
