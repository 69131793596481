define([
  'jquery',
  'underscore',
  'backbone',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/selectGiftCardForRefundPopup.hbs',

  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/upx/components/upx',
  'modules/shop.cash-register-retail/components/toaster',

  'modules/admin/behaviors/loader',
  'modules/shop.cash-register-retail/views/popups/selectGiftCardForRefundPopup/table',
  'modules/shop.cash-register-retail/views/popups/giftcardPopup',
], (
  $, _, Backbone, PopupView, Template,
  Locale, CashRegisterApi, Upx, Toaster,
  Loader, TableView, GiftcardPopupView,
) => PopupView.extend({
  template: Template,

  className: 'dialog',

  ui: {
    close: '[data-action="close"]',
    createNew: '[data-action="create-new"]',
  },

  events: {
    'click @ui.close': 'closeClicked',
    'touchend @ui.close': 'closeClicked',

    'click @ui.createNew': 'createNewClicked',
    'touchend @ui.createNew': 'createNewClicked',
  },

  regions: {
    table: '[data-region="table"]',
  },

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  initialize({ giftCardCollection, totalPriceWt }) {
    this.giftCardCollection = giftCardCollection;
    this.totalPriceWt = totalPriceWt;
    this.tableView = new TableView({
      collection: this.giftCardCollection,
    });
    this.tableView.on('use:giftcard', (model) => {
      this.closeDef.resolve({
        collection: [model],
      });
      this.close();
    });
  },

  showLoader() {
    this.loaderDef = this.loader.startLoader();
  },

  hideLoader() {
    if (this.loaderDef) this.loaderDef.resolve();
    this.loaderDef = null;
  },

  renderTable() {
    this.getRegion('table').show(this.tableView);
  },

  onRender() {
    this.renderTable();
  },

  open(def) {
    def = this.renderInFloatingRegion(def);

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/selectGiftCardForRefundPopup.js',
    });

    this.openPopup();

    return def;
  },

  createNewClicked() {
    const viewDef = new $.Deferred();

    this.closeDef.reject({
      newDef: viewDef,
    });
    const view = new GiftcardPopupView({
      initialBalance: this.totalPriceWt * -1,
    });

    view.open(viewDef);
  },

}));
