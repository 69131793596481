define([
  'jquery',
  'backbone',
  'modules/shop.cash-register-retail/templates/popups/repairMoreDetailsPopup/item.hbs',

  'modules/upx/components/upx',
  'modules/common/components/moment',
], ($, Backbone, Template,
  Upx, Moment) => Backbone.Marionette.ItemView.extend({
  template: Template,

  serializeData() {
    return {
      ...this.model.toJSON(),
      ...{
        image_url: Upx.getResourceUrl(this.model.get('file.download_url')),
        date_deleted: (new Moment(this.model.get('date_deleted'))).format('LLL'),
      },
    };
  },
}));
