define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/popups/loyaltyPointsPaymentPopup/balanceRemaining.hbs',

  'modules/shop.cash-register-retail/models/upx/DefaultShopConfiguration',

  'modules/common/components/currency',
], (
  $, _, Backbone, Template,
  DefaultShopConfiguration,
  Currency,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  calculateRemainingBalance() {
    return Currency.Math.subtract(
      this.model.get('currentValue'),
      this.model.get('exchangeValue'),
    );
  },

  serializeData() {
    return {
      remainingBalance: Currency.format(
        DefaultShopConfiguration.getCurrencyIso3(),
        this.calculateRemainingBalance(),
      ),
    };
  },

}));
