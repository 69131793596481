define([
  'jquery',
  'backbone',
  'underscore',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/massPackingSlipPrintPopup.hbs',

  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/shop.cash-register-retail/components/printing',
  'toastr',

], (
  $, Backbone, _, PopupView, Template,
  Locale, CashRegisterApi, PrintingComponent, Toastr,
) => PopupView.extend({
  template: Template,

  className: 'dialog',

  ui: {
    close: '[data-action="close"]',
    title: '[data-ui="title"]',
    stopPrint: '[data-ui="stop-print"]',
  },

  events: {
    'click @ui.close': 'closeClicked',
    'touchend @ui.close': 'closeClicked',

    'click @ui.stopPrint': 'stopPrintClicked',
    'touchend @ui.stopPrint': 'stopPrintClicked',
  },

  initialize({ orderCollection }) {
    this.orderCollection = orderCollection;
    this.printState = {};
    this.printDone = false;
    this.isCancelled = false;
    this.isError = false;
    this.title = '';
  },

  printEnded() {
    this.printDone = true;
    this.renderDebounce();
  },

  printProgress(printState) {
    this.printState = printState;
    const { ordersToPrintTotal, ordersToPrintProgress } = printState;
    if (ordersToPrintProgress === ordersToPrintTotal) {
      this.printEnded();
    } else {
      this.renderDebounce();
    }
  },

  stopPrintClicked() {
    this.isCancelled = true;
  },

  serializeData() {
    const {
      ordersToPrintTotal,
      ordersToPrintProgress,
    } = this.printState;

    const printPercentage = ordersToPrintProgress
      ? ((ordersToPrintProgress / ordersToPrintTotal) * 100).toFixed(2)
      : 0;

    let orders_total_count = ordersToPrintTotal;
    let orders_printed_count = ordersToPrintProgress;

    if (this.isError && !ordersToPrintTotal) {
      orders_total_count = 0;
    }

    if (this.isError && !ordersToPrintProgress) {
      orders_printed_count = 0;
    }

    return {
      ...this.printState,
      orders_total_count,
      orders_printed_count,
      title: this.title,
      printPercentage,
      printDone: this.printDone,
      isCancelled: this.isCancelled,
      isError: this.isError,
    };
  },

  renderDebounce: _.debounce(function () {
    if (!this.isDestroyed) {
      this.render();
    }
  }, 250),

  startPrinting() {
    const orderIds = this.orderCollection.map((orderModel) => orderModel.get('id'));
    this.processPrint(orderIds);
  },

  processPrint(orderIds, printCounter = 0) {
    const currentOrderModel = this.orderCollection.get(orderIds[printCounter]);

    if ((printCounter + 1) <= orderIds.length) {
      printCounter++;
      this.print(currentOrderModel, printCounter).then(() => {
        this.printProgress({
          ordersToPrintProgress: printCounter,
          ordersToPrintTotal: this.orderCollection.length,
        });
        this.processPrint(orderIds, printCounter);
      }).catch((e) => {
        if (this.isCancelled) {
          Toastr.warning(Locale.translate('printing_has_been_cancelled'));
        } else {
          Toastr.error(e.error || Locale.translate('there_was_an_error_while_printing'));
          this.isError = true;
        }

        this.printEnded();
      });
    } else {
      this.printEnded();
    }
  },

  print(orderModel) {
    return new Promise((resolve, reject) => {
      if (this.isCancelled) {
        reject(new Error(Locale.translate('printing_has_been_cancelled')));
      }

      orderModel.collection.trigger('lock:print'); // Locks printing
      orderModel.loadOrderItems().then(() => {
        orderModel.loadShippedOrderItemSerials().then(() => {
          PrintingComponent.printOrderPackingSlip(orderModel).then(resolve, reject).always(() => {
            orderModel.collection.trigger('unlock:print');
          });
        }, reject).always(() => {
          orderModel.collection.trigger('unlock:print');
        });
      }, reject).always(() => {
        orderModel.collection.trigger('unlock:print');
      });
    });
  },

  open(title, def) {
    def = this.renderInFloatingRegion(def);

    this.title = title;

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/massPackingSlipPrintPopup',
      title,
    });

    this.openPopup();
    this.renderDebounce();
    this.startPrinting();

    return def;
  },

}));
