define([
  'application',
  'backbone',
  'modules/shop.cash-register-retail/views/popups/orderMemoryPopup/item',
],
(App, Backbone, ItemView) => Backbone.Marionette.CollectionView.extend({
  tagName: 'tbody',
  childView: ItemView,

  childViewOptions(model) {
    return {
      model,
      popupView: this.options.popupView,
    };
  },
  viewComparator(a, b) {
    const aid = a.get('id');
    const bid = b.get('id');
    if (aid === bid) {
      return 0;
    }
    return aid <= bid ? 1 : -1;
  },
}));
