define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/popups/selectGiftCardForRefundPopup/table.hbs',
  './tableRow',
], (
  $, _, Backbone, Template, TableRowView,
) => Backbone.Marionette.CompositeView.extend({

  template: Template,

  tagName: 'table',

  className: 'table',

  childView: TableRowView,

  childViewContainer: 'tbody',

  childEvents: {
    use: 'useGiftCard',
  },

  useGiftCard(childView, model) {
    this.triggerMethod('use:giftcard', model);
  },
}));
