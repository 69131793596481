define([
  'jquery',
  'underscore',
  'backbone',
], (
  $, _, Backbone,
) => Backbone.Marionette.LayoutView.extend({

  initialize() {
    this.selection = this.options.selection;
  },

  getAddons() {
    const addons = {};
    const items = this.model.get('shop_product_addon_items');
    const group_id = this.model.get('product_addon_group.id');

    if (_.isArray(items)) {
      items.forEach((item) => {
        const item_id = `${group_id}::${item.shop_product_id}`;
        item.id = item_id;
        addons[item_id] = item;
      });
    }

    return addons;
  },

  addSelection(id) {
    const addons = this.getAddons();
    const addon = addons[id];
    if (addon) {
      this.selection.add(addon);
    }
  },

  removeSelection(id) {
    const selection = this.selection.get(id);
    if (selection) {
      this.selection.remove(selection);
    }
  },

  serializeData() {
    return {
      addons: this.getAddons(),
      title: this.model.get('product_addon_group.title'),
    };
  },

}));
