define([
  'jquery',
  'underscore',
  'backbone',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/openCCVPinTransactionPopup.hbs',
  'modules/common/components/locale',
  'toastr',

  'modules/admin/behaviors/loader',
  'modules/shop.cash-register-retail/components/cashRegisterApi',

  './openCCVPinTransactionPopup/swappable',
], (
  $, _, Backbone, PopupView, Template, Locale, Toastr,
  Loader, CashRegisterApi,
  SwappableView,
) => PopupView.extend({
  template: Template,

  className: 'dialog dialog--ccv-unknown-transaction-result',

  regions: {
    swappable: '[data-region="swappable"]',
  },

  ui: {
    close: '[data-action="close"]',
  },

  events: {
    'click @ui.close': 'closeClicked',
    'touchend @ui.close': 'closeClicked',
  },

  initialize(options) {
    this.transaction = options.transaction;
  },

  renderContent() {
    const region = this.getRegion('swappable');

    const view = new SwappableView({
      transaction: this.transaction,
      close: () => this.close(),
    });
    region.show(view);
  },

  open(def) {
    def = this.renderInFloatingRegion(def);

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/openCCVPinTransactionPopup',
    });

    this.openPopup();
    this.renderContent();

    return def;
  },

  onDestroy() {
    this.close();
  },

}));
