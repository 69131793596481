define([
  'jquery',
  'underscore',
  'modules/mobile/views/popup',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/templates/popups/emailInvoicePopup',

  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/shop.cash-register-retail/models/keyboard',
  'modules/shop.cash-register-retail/components/email.js',

  'modules/admin/components/select2',
], (
  $, _, PopupView, Locale, Template,
  CashRegisterApi, KeyboardModel, Email,
) => PopupView.extend({
  template: Template,

  className: 'dialog',

  ui: {
    close: '[data-action="close"]',
    submit: '[data-action="submit"]',
    message: '[data-ui="message"]',
    title: '[data-ui="title"]',
    input: '[data-ui="input"]',
  },

  events: {
    'click @ui.close': 'closeClicked',
    'touchend @ui.close': 'closeClicked',

    'click @ui.submit': 'submitClicked',
    'touchend @ui.submit': 'submitClicked',

    'click [data-action="clear"]': 'clearClicked',
    'touchend [data-action="clear"]': 'clearClicked',
  },

  clearClicked() {
    this.ui.input.val('');
  },

  initialize({ emails }) {
    this.emails = this.cleanEmails(emails);
  },

  cleanEmails(emails) {
    const initialEmails = [];
    const known = [];

    emails.forEach(({ email, name }) => {
      email = Email.cleanEmptyEmail(email);
      if (email) {
        const knownKey = `${email}|${name}`;
        if (known.indexOf(knownKey) === -1) {
          initialEmails.push({ email, name });
          known.push(knownKey); // making uniq
        }
      }
    });

    return initialEmails;
  },

  spawnSelect2() {
    const inputEl = this.ui.input;
    const select2Options = {
      placeholder: Locale.translate('select_or_type_email'),
      containerCssClass: 'container-white form-control',
      dropdownCssClass: 'dropdown-white shop-product-search-simple__dropdown',
      minimumInputLength: 0,
      width: '100%',
      allowClear: true,
      selectOnClose: true,
      openOnEnter: true,
      query: (query) => {
        this.emailSearch(query);
      },
      createSearchChoice: (term) => this.getSelect2Data({ email: term }),
    };

    inputEl.select2(select2Options);

    if (this.emails.length > 0) {
      inputEl.select2('data', this.getSelect2Data(this.emails[0]));
    }
  },

  getSelect2Data({ email, name }) {
    const text = name
      ? `${name} <${email}>`
      : email;

    return {
      id: email,
      text,
    };
  },
  emailSearch(query) {
    let emails = JSON.parse(JSON.stringify(this.emails));
    if (query.term) {
      const term = query.term.toLowerCase().trim();
      if (term !== '') {
        emails = emails.filter(
          ({ email, name }) => email.toLowerCase().indexOf(term) !== -1
            || (name && name.toLowerCase().indexOf(term) !== -1),
        );
      }
    }
    const results = [];
    emails.forEach((email) => results.push(this.getSelect2Data(email)));
    query.callback({
      results,
    });
  },

  onRender() {
    this.spawnSelect2();
  },

  onShow() {
    PopupView.prototype.onShow.apply(this);
    KeyboardModel.setViewWithMode(this.cid, KeyboardModel.MODE_STRING);
  },
  onDestroy() {
    PopupView.prototype.onDestroy.apply(this);
    KeyboardModel.setNeutralMode();
  },

  open(def) {
    def = this.renderInFloatingRegion(def);

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/emailInvoicePopup',
      emails: this.emails,
    });

    this.openPopup();

    return def;
  },

  submitClicked: _.debounce(function () {
    const val = this.ui.input.val();
    if (val.length > 0 && val.includes('@') && val.includes('.')) {
      this.closeDef.resolve(val);
      this.close();
    }
  }),

}));
