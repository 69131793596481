define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/views/swappableWithLoading',

  './view',
  './duration',
], (
  $, _, Backbone, SwappableView,
  ViewView, DurationView,
) => SwappableView.extend({

  initialize(options) {
    SwappableView.prototype.initialize.call(this, options);

    this.setMainView(ViewView, {
      model: options.model,
    });

    this.setView('duration', DurationView, {
      model: options.model,
    });
  },
}));
