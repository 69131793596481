define([
  'application',
  'backbone',
  'modules/shop.cash-register-retail/templates/popups/orderMemoryPopup/item.hbs',
  'modules/shop.cash-register-retail/collections/currentOrderItem',

  'modules/shop.cash-register-retail/views/popups/confirmPopup',
  'modules/shop.cash-register-retail/components/orderMemory',
  'modules/common/components/locale',
],
(App, Backbone, Template, OrderItemCollection,
  ConfirmPopup, OrderMemoryComponent, Locale) => Backbone.Marionette.LayoutView.extend({
  tagName: 'tr',
  template: Template,

  events: {
    'click [data-action="delete"]': 'deleteClicked',
    'click [data-action="load"]': 'loadClicked',
  },

  regions: {
    popup: '[data-region=popup]',
  },

  deleteClicked: _.debounce(function () {
    this.model.destroy();
  }, 10),

  loadClicked: _.debounce(function () {
    const def = new $.Deferred();
    const { model } = this;
    const self = this;

    def.done(() => {
      OrderMemoryComponent.restoreCurrent(model);
      self.options.popupView.close();
    });

    if (OrderItemCollection.getTotalItems() > 0) {
      // replacing lets ask
      const view = new ConfirmPopup();
      view.open(
        Locale.translate('are_you_sure_you_want_to_replace_current_order_question'),
        null,
        def,
      );
    } else {
      def.resolve();
    }
  }, 10),
}));
