define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/popups/hospitalityMembercardPopup/createCustomer.hbs',
  'modules/shop.cash-register-retail/views/customers/create',
  'modules/shop.cash-register-retail/models/selectedCustomer',

  'modules/shop.cash-register-retail/models/keyboard',
], (
  $, _, Backbone, Template, CreateView, SelectedCustomerModel,
  Keyboard,
) => CreateView.extend({

  template: Template,

  onShow() {
    CreateView.prototype.onShow.call(this);
    this.keyboardInterval = setInterval(() => {
      Keyboard.setViewWithMode(this.cid, Keyboard.MODE_POPUP);
    }, 100);
  },

  onDestroy() {
    clearInterval(this.keyboardInterval);
    Keyboard.resetMode();
  },

  onSaved(model) {
    SelectedCustomerModel.clear();
    SelectedCustomerModel.set(model.toJSON());
    SelectedCustomerModel.save();
    this.triggerMethod('layout:swap', null, { customerModel: model });
  },

  backClicked() {
    this.triggerMethod('layout:swap');
  },

}));
