define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/popups/addLoyaltyOperationPopup.hbs',

  'modules/admin/behaviors/loader',
  'modules/upx/behaviors/validation',
  'modules/upx/behaviors/modelBinder',

  'modules/admin/templates/alerts/danger',

  'upx.modules/ShopModule/models/LoyaltyCustomerOperation',
  'modules/shop.cash-register-retail/collections/upx/LoyaltyOperationTypes',

  'modules/common/components/locale',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
], (
  $, _, Backbone, Template,
  Loader, Validation, ModelBinder,
  DangerAlert,
  LoyaltyCustomerOperationModel, LoyaltyOperationTypes,
  Locale, PopupView, CashRegisterApi,
) => PopupView.extend({
  template: Template,

  className: 'dialog dialog--confirm',

  ui: {
    typeBtn: 'button[data-action="operationType"]',
    pointCalc: '[data-ui="point-calculation"]',
    positiveInput: 'input[name="positive_point_change"]',

    confirm: '[data-action="confirm"]',
    close: '[data-action="close"]',
  },

  events: {
    'click @ui.typeBtn': 'onSelectType',
    'blur @ui.positiveInput': 'onPointsChange',
    'change @ui.positiveInput': 'onPointsChange',

    'submit @ui.form': 'confirmClicked',

    'click @ui.close': 'closeClicked',
    'touchend @ui.close': 'closeClicked',

    'click @ui.confirm': 'onSave',
    'touchend @ui.confirm': 'onSave',
  },

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
    ModelBinder: {
      behaviorClass: ModelBinder,
      method: 'newLoyaltyCustomerOperation',
    },
    Validation: {
      behaviorClass: Validation,
      method: 'newLoyaltyCustomerOperation',
      rules: {
        comment: {
          required: true,
        },
        positive_point_change: {
          required: true,
          min: 1,
        },
      },
    },
  },

  initialize(options) {
    this.addPoints = !!options.addPoints;
    this.inCheckout = !!options.inCheckout;

    if (!options.customerModel) {
      throw new Error('customerModel is not passed to options');
    }
    if (!options.safeDef) {
      throw new Error('safeDef is not passed to options');
    }
    this.safeDef = options.safeDef;
    this.customerModel = options.customerModel;

    if (!this.customerModel.has('loyalty_customer')) {
      throw new Error('customerModel has no loyalty_customer');
    }
    this.currentPoints = this.customerModel.get('loyalty_customer.point_total');
    this.model = new LoyaltyCustomerOperationModel({
      loyalty_customer_id: this.customerModel.get('loyalty_customer.id'),
    });
  },

  open(def) {
    def = this.renderInFloatingRegion(def);

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/addLoyaltyOperationPopup',
    });

    this.openPopup();

    setTimeout(() => {
      this.ui.positiveInput.focus();
      this.ui.positiveInput.click();
    }, 100);

    return def;
  },

  onShow() {
    const other = LoyaltyOperationTypes.where({ alias: 'Other' })[0];
    this.selectType(other.get('id'));
    this.onPointsChange();
  },

  serializeData() {
    return {
      types: LoyaltyOperationTypes.toJSON(),
      addPoints: this.addPoints,
      customer: this.customerModel.toJSON(),
      inCheckout: this.inCheckout,
    };
  },

  onPointsChange() {
    const points = parseInt(this.ui.positiveInput.val(), 10) || 0;
    let newPoints = this.currentPoints;
    if (this.addPoints) {
      newPoints += points;
    } else {
      newPoints -= points;
    }
    this.ui.pointCalc.text(
      Locale.translate('current_point_value_{current}_new_value_{new_points}', {
        current: this.currentPoints,
        new_points: newPoints,
      }),
    );
  },
  onSelectType(e) {
    const $e = $(e.currentTarget);
    this.selectType($e.val());
  },
  selectType(id) {
    this.ui.typeBtn.removeClass('active');
    const btn = this.ui.typeBtn.filter(`[value="${id}"]`);
    btn.addClass('active');
    this.model.set('loyalty_operation_type_id', id);
  },
  onSave() {
    this.model.set('point_change', this.model.get('positive_point_change'));
    if (!this.addPoints) {
      this.model.set('point_change', -this.model.get('point_change'));
    }
    if (this.model.isValid(true)) {
      const def = this.loader.startLoader();
      $.when(
        this.model.save(),
      ).then(
        () => {
          this.safeDef.resolve();
          this.close();
          def.resolve();
        },
        (response) => {
          def.reject();
          this.$el.find('#message').html(
            DangerAlert({
              id: 'error',
              dismissable: true,
              title: Locale.translate('error'),
              description: response.error,
            }),
          );
        },
      );
    }
  },
}));
