define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/popups/orderDetailsPopup/invoices.hbs',

  './invoiceRow',
], (
  $, _, Backbone, Template,
  ItemView,
) => Backbone.Marionette.CompositeView.extend({

  template: Template,

  childView: ItemView,

  childViewContainer: 'tbody',

  childViewOptions() {
    return {
      onCloseClicked: this.options.onCloseClicked,
    };
  },

}));
