define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/popups/calendarEventPopup/treatments/category.hbs',

  './products',
], (
  $, _, Backbone, Template,
  ProductsView,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'treatments__category',

  initialize(options) {
    this.appointmentModel = options.appointmentModel;
    this.searchModel = options.searchModel;
  },

  onShow() {
    this.searchModel.on('change:query', this.render, this);
  },

  onDestroy() {
    this.searchModel.off('change:query', this.render, this);
  },

  regions: {
    products: '[data-region="products"]',
  },

  onRender() {
    const region = this.getRegion('products');
    const view = new ProductsView({
      searchModel: this.searchModel,
      categoryModel: this.model,
      appointmentModel: this.appointmentModel,
    });
    region.show(view);
  },

  serializeData() {
    const data = this.model.toJSON();
    if (this.searchModel.get('query') !== '') {
      data.active = true;
    }
    return data;
  },

  events: {
    'click [data-action="category"]': 'categoryClicked',
  },

  categoryClicked() {
    this.model.set('active', !this.model.get('active'));
  },

  modelEvents: {
    'change:active': 'render',
  },

}));
