define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/popups/giftcardManagePopup/table.hbs',
  './tableRow',
], (
  $, _, Backbone, Template, TableRowView,
) => Backbone.Marionette.CompositeView.extend({

  template: Template,

  tagName: 'table',

  className: 'table',

  childView: TableRowView,

  childViewContainer: 'tbody',

}));
