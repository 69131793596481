define([
  'jquery',
  'underscore',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/confirmPopup',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
], (
  $, _, PopupView, Template, Locale, CashRegisterApi,
) => PopupView.extend({
  template: Template,

  className: 'dialog dialog--confirm',

  ui: {
    confirm: '[data-action="confirm"]',
    close: '[data-action="close"]',
    title: '[data-ui="title"]',
    message: '[data-ui="message"]',
  },

  events: {
    'click @ui.close': 'closeClicked',
    'touchend @ui.close': 'closeClicked',

    'click @ui.confirm': 'confirmClicked',
    'touchend @ui.confirm': 'confirmClicked',
  },

  open(title, message, def) {
    def = this.renderInFloatingRegion(def);

    this.ui.title.text(title);
    if (message) {
      this.ui.message.show();
      this.ui.message.html(message);
    } else {
      this.ui.message.hide();
      this.ui.message.html('');
    }

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/confirmPopup',
      message,
      title,
    });

    this.openPopup();

    return def;
  },

}));
