define([
  'jquery',
  'underscore',
  'backbone',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/orderAgainPopup.hbs',

  'modules/admin/behaviors/loader',
  'modules/common/components/locale',
  'modules/common/components/currency',
  'modules/common/components/historyBreadcrumb',

  'modules/shop.cash-register-retail/collections/currentOrderItem',
  'modules/shop.cash-register-retail/models/selectedCustomer',
  'modules/shop.cash-register-retail/models/upx/DefaultShopConfiguration',

  'modules/shop.cash-register-retail/components/cashRegisterApi',
], (
  $, _, Backbone, PopupView, Template,
  Loader, Locale, Currency, HistoryBreadcrumb,
  CurrentOrderItemCollection, SelectedCustomerModel, ShopConfiguration,
  CashRegisterApi,
) => PopupView.extend({
  template: Template,

  className: 'dialog dialog--order-again-popup',

  ui: {
    close: '[data-action="close"]',
    addProducts: '[data-action="add-products"]',
    cleanCheckout: '[data-action="clean-checkout"]',
    message: '[data-ui="message"]',
  },

  events: {
    'click @ui.close': 'closeClicked',
    'touchend @ui.close': 'closeClicked',
    'click @ui.addProducts': 'addProductsClicked',
    'click @ui.cleanCheckout': 'cleanCheckoutClicked',
  },

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  initialize(options) {
    this.orderItemCollection = options.orderItemCollection || CurrentOrderItemCollection;
    this.selectedCustomerModel = options.selectedCustomerModel || SelectedCustomerModel;
    this.relationModel = null;

    PopupView.prototype.initialize.call(this, options);
  },

  open(def) {
    def = this.renderInFloatingRegion(def);

    this.closeDef = def;

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/orderAgainPopup',
    });

    this.openPopup();

    return def;
  },

  addProductsClicked() {
    CurrentOrderItemCollection.addProductByOrderModel(this.model);
    this.finalizeCopyOrder();
  },

  cleanCheckoutClicked() {
    CurrentOrderItemCollection.addProductByOrderModel(this.model, true);
    this.finalizeCopyOrder();
  },

  finalizeCopyOrder() {
    if (this.relationModel) {
      SelectedCustomerModel.set(this.relationModel.toJSON());
    }
    HistoryBreadcrumb.goto('checkout');
    this.close();
  },

  setRelationModel(relationModel) {
    this.relationModel = relationModel;
  },

  onRender() {
    this.ui.message.text(
      Locale.translate('there_are_already_{item_count}_items_in_your_checkout_value_of_{amount}_do_you_want_to_clean_the_checkout_or_just_add_extra_items',
        {
          item_count: this.orderItemCollection.getTotalItems(),
          amount: Currency.format(
            ShopConfiguration.getCurrencyIso3(),
            this.orderItemCollection.getTotalPrice(),
          ),
        }),
    );
  },

}));
