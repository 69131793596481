define([
  'jquery',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/memberCardListPopup',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/views/memberCards/list/collection',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
], (
  $, PopupView, Template, Locale, CollectionView, CashRegisterApi,
) => PopupView.extend({
  template: Template,

  className: 'dialog',

  ui: {
    close: '[data-action="close"]',
    message: '[data-ui="message"]',
    title: '[data-ui="title"]',
    customerName: '[data-ui="customer_name"]',
  },

  events: {
    'click @ui.close': 'closeClicked',
    'touchend @ui.close': 'closeClicked',
  },

  regions: {
    list: '[data-region="list"]',
  },

  open(customerCollection, relationModel, def) {
    def = this.renderInFloatingRegion(def);
    if (relationModel) {
      this.ui.customerName.text(relationModel.get('name'));
    }

    const region = this.getRegion('list');
    const view = new CollectionView({
      collection: customerCollection,
    });
    region.show(view);

    const self = this;
    view.on('card:selected', (model) => {
      def.resolve(model);
      self.close();
    });

    view.on('start:loader', () => {
      self.loader = self.startLoader();
    });

    view.on('stop:loader', () => {
      self.loader.resolve();
    });

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/memberCardListPopup',
    });

    this.openPopup();

    return def;
  },

}));
