define([
  'jquery',
  'underscore',
  'backbone',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/productInformationPopup.hbs',
  'modules/shop.cash-register-retail/components/printing',
  'modules/shop.cash-register-retail/views/popups/messagePopup',

  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/toaster',
  'modules/common/components/currency',

  'upx.modules/ProductsModule/collections/ProductPrice',
  'modules/shop.cash-register-retail/collections/upx/ProductPriceType',
  'modules/shop.cash-register-retail/models/settings/priceTagPrinter',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/shop.common/components/commerceAuth',

  'magnific-popup',
], (
  $, _, Backbone, PopupView, Template, PrintingComponent, MessagePopup,
  Locale, Toaster, Currency,
  ProductPriceCollection, ProductPriceTypeCollection, PriceTagPrinter, CashRegisterApi, CommerceAuth,
) => PopupView.extend({
  template: Template,

  className: 'dialog dialog--product-information',

  ui: {
    close: '[data-action="close"]',
    print: '[data-action="print"]',
    add: '[data-action="add"]',
    attributePopup: '[data-region="attribute-popup"]',
    imgGallery: '.img-gallery',
    purchasePrice: '[data-ui="purchase-price"]',
  },

  regions: {
    popup: '[data-region="popup"]',
  },

  events: {
    'click @ui.close': 'closeClicked',
    'touchend @ui.close': 'closeClicked',
    'click @ui.print': 'printClicked',
    'touchend @ui.print': 'printClicked',
  },

  onRender() {
    if (this.showPurchasePrice()) this.renderPurchasePrice();
  },

  async renderPurchasePrice() {
    try {
      const typeModel = await this.fetchPurchaseTypeModel();
      const priceModel = await this.fetchPriceModel(typeModel);

      let text = '-';
      if (priceModel) {
        text = Currency.format(
          priceModel.get('currency_iso3'),
          Currency.toCurrency(priceModel.get('ppu_wt')),
        );
      }
      this.ui.purchasePrice.text(text);
    } catch (e) {
      Toaster.error(e.error);
      this.ui.purchasePrice.text('-');
    }
  },

  fetchPurchaseTypeModel() {
    return new Promise((resolve, reject) => {
      const collection = new ProductPriceTypeCollection();
      const params = {
        start: 0,
        limit: 1,
        filters: [
          {
            name: 'alias__=',
            val: 'Purchase',
          },
          {
            name: 'module_name__=',
            val: 'ProductsModule',
          },
        ],
      };

      collection.fetch({ params })
        .then(() => {
          const typeModel = collection.first();
          if (typeModel) {
            resolve(typeModel);
          } else {
            reject({ error: Locale.translate('unable_to_get_the_purchase_price_due_to_an_internal_error') });
          }
        });
    });
  },

  fetchPriceModel(priceTypeModel) {
    return new Promise((resolve, reject) => {
      const collection = new ProductPriceCollection();
      const params = {
        start: 0,
        limit: 1,
        sort: [],
        filters: [{
          name: 'product_id__=',
          val: this.model.get('product_id'),
        }, {
          name: 'product_price_type_id__=',
          val: priceTypeModel.get('id'),
        }, {
          name: 'price_list_id__null',
          val: '1',
        }],
      };

      collection.fetch({ params })
        .then(() => resolve(collection.first()), reject);
    });
  },

  closeClicked: _.debounce(() => {
    $.magnificPopup.close();
  }, 100),

  printClicked: _.debounce(function () {
    const def = new $.Deferred();
    PrintingComponent.printPriceTag(this.model)
      .then(() => {
        def.resolve();
      }, (response) => {
        if (response && 'error' in response) {
          def.reject();

          const view = new MessagePopup();
          view.open(
            response.error,
          );
        }
      });
  }, 100),

  onShow() {
    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/productInformationPopup',
    });

    this.openPopup({
      removalDelay: 300,
    });

    this.ui.imgGallery.magnificPopup({
      delegate: 'a',
      type: 'image',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        arrowMarkup: '<button type="button" class="mfp-arrow mfp-arrow-%dir%" title="%title%"></button>',
        tPrev: 'Previous',
        tNext: 'Next',
        tCounter: '<span class="mfp-counter">%curr% of %total%</span>',
      },
      image: { titleSrc: 'title' },
    });
  },

  showPurchasePrice() {
    return CommerceAuth.hasAdminAccess() || CommerceAuth.hasManagerAccess() || CommerceAuth.hasUserAccess();
  },

  serializeData() {
    const self = this;
    return {
      product_model: self.model.toJSON(),
      has_cups_printer: PriceTagPrinter.isWantedType(),
      showPurchasePrice: this.showPurchasePrice(),
    };
  },

}));
