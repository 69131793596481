define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/popups/quantityPopup',
  'modules/mobile/views/popup',

  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/shop.cash-register-retail/models/keyboard',
], (
  $, _, Backbone, Template, PopupView,
  CashRegisterApi, Keyboard,
) => PopupView.extend({

  template: Template,

  className: 'dialog',

  ui: {
    quantity: '[data-ui="quantity"]',
  },

  events: {
    'click [data-action="close"]': 'closeClicked',
    'touchend [data-action="close"]': 'closeClicked',

    'click [data-action="confirm"]': 'confirmClicked',
    'touchend [data-action="confirm"]': 'confirmClicked',

    'change [data-action="quantity"]': 'quantityChanged',
  },

  confirmClicked: _.debounce(function () {
    if (this.quantity > 0) {
      this.closeDef.resolve(this.quantity);
    } else {
      this.closeDef.reject();
    }
    this.closePopup();
  }),

  quantityChanged(ev) {
    const el = ev.currentTarget;
    const valueRaw = el.value;
    let value = parseInt(valueRaw);

    // Check if value is empty
    if (valueRaw === '') {
      this.quantity = 0;
      return;
    }

    // Check if value is wrong
    if (_.isNaN(value)) {
      value = this.quantity;
    } else {
      // Else update quantity.
      this.quantity = value;
    }
    el.value = value;
    $(el).trigger('click');
  },

  open(initialQuantity, def) {
    def = this.renderInFloatingRegion(def);
    // setting quantity
    this.quantity = initialQuantity ? parseInt(initialQuantity) : 1;
    this.ui.quantity.val(this.quantity);

    this.openQuantityPopup();

    // setting deferred and returning it.
    return def;
  },

  closePopup() {
    PopupView.prototype.closePopup.call(this);

    // Reset mode, allowing the catalog search to work again.
    Keyboard.resetMode();
    Keyboard.off('key:confirm', this.confirmClicked, this);
  },

  openQuantityPopup() {
    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/quantityPopup',
    });

    // key mode to prevent the catalog search to trigger
    Keyboard.setViewWithMode(this.cid, Keyboard.MODE_QUANTITY_POPUP);
    Keyboard.on('key:confirm', this.confirmClicked, this);

    this.openPopup();
  },

}));
