define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/popups/orderDetailsPopup/invoiceRow.hbs',
  'modules/shop.cash-register-retail/components/sendInvoice',

  'modules/admin/behaviors/loader',
  'modules/shop.cash-register-retail/collections/routeCache',
], (
  $, _, Backbone, Template, SendInvoice,
  Loader, RouteCache,
) => Backbone.Marionette.LayoutView.extend({

  tagName: 'tr',

  template: Template,

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  events: {
    'click [data-action=pay]': 'payClicked',
    'click [data-action=send]': 'sendClicked',
  },

  initialize(options) {
    this.onCloseClicked = options.onCloseClicked;
  },

  payClicked() {
    const cacheId = RouteCache.createCache({
      invoiceId: this.model.get('invoice.id'),
    });
    this.onCloseClicked();
    Backbone.history.navigate(`payment-screen/invoice/${cacheId}`, { trigger: true });
  },

  sendClicked() {
    const def = this.loader.startLoader();
    SendInvoice.sendById({
      invoiceId: this.model.get('invoice_id'),
      def, // SendInvoice.sendById triggers reject/resolve
    });
  },
  serializeData() {
    const invoice = this.model.get('invoice');
    return invoice;
  },
}));
