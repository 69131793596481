define([
  'jquery',
  'underscore',
  'backbone',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/customerInvoicePopup/layout',

  './customerInvoices',

  'modules/common/collections/currencies',

  'modules/shop.cash-register-retail/components/cashRegisterApi',

  'modules/common/components/locale',
], (
  $, _, Backbone, PopupView, Template,
  CustomerInvoicesTable,
  CurrenciesCollection,
  CashRegisterApi,
  Locale,
) => PopupView.extend({

  template: Template,

  className: 'dialog dialog--customer-invoice',

  events: {
    // close
    'click [data-action=close]': 'closeClicked',
    'touchend [data-action=close]': 'closeClicked',
    'click [data-actions=customer-invoices]': 'customerInvoicesClicked',
  },

  regions: {
    notes: '[data-region=notes]',
  },

  initialize({
    customerHasNotes,
    customerRelationDataId,
    invoiceStatModel = new Backbone.Model(),
    discountPercentage = null,
  }) {
    this.customerHasNotes = customerHasNotes;
    this.invoiceStatModel = invoiceStatModel;
    this.customerRelationDataId = customerRelationDataId;
    this.discountPercentage = discountPercentage;
  },

  onRender() {
    if (this.customerHasNotes) this.renderCustomerNotes();
  },

  renderCustomerNotes() {
    const region = this.getRegion('notes');
    const view = new CustomerInvoicesTable();
    region.show(view);
  },

  customerInvoicesClicked() {
    Backbone.history.navigate(`customers/details/${this.customerRelationDataId}/outstanding`, { trigger: true });
    this.close();
  },

  open() {
    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/customerInvoicePopup/layout',
    });

    this.renderInFloatingRegion();

    this.openPopup();
  },

  serializeData() {
    const totalOpenInvoices = this.invoiceStatModel.get('total');
    return {
      discount_percentage: this.discountPercentage,
      customerHasNotes: this.customerHasNotes,
      hasOpenInvoice: totalOpenInvoices,
      description: Locale.translate('this_customer_has_at_the_moment_{invoice_quantity}_invoice_s_partially_open_for_the_total_amount_of_{currency_symbol}{invoice_price}_where_the_full_amount_is_padded_the_payment_due_date', {
        invoice_quantity: totalOpenInvoices,
        currency_symbol: CurrenciesCollection.getCurrencySymbolUsingIso3(this.invoiceStatModel.get('currency_iso3')),
        invoice_price: this.invoiceStatModel.get('value_outstanding_wt'),
      }),
    };
  },

}));
