define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/popups/selectGiftCardForRefundPopup/tableRow.hbs',
  'modules/shop.cash-register-retail/models/upx/DefaultShopConfiguration',
], (
  $, _, Backbone, Template, DefaultShopConfiguration,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  tagName: 'tr',

  events: {
    'click [data-action="use"]': 'useClicked',
  },

  useClicked() {
    this.trigger('use', this.model);
  },

  serializeData() {
    const data = this.model.toJSON();
    data.currency_iso3 = DefaultShopConfiguration.getCurrencyIso3();
    return data;
  },

}));
