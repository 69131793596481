define([
  'jquery',
  'underscore',
  'backbone',
  'modules/mobile/views/popup',
  'modules/upx/components/upx',
  'modules/admin/behaviors/loader',
  'modules/common/components/locale',

  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/common/components/moment',
  'modules/shop.cash-register-retail/components/onlineFoodOrder',

  'modules/shop.cash-register-retail/components/timepicker',
  'modules/shop.cash-register-retail/views/popups/messagePopup',
  'modules/shop.cash-register-retail/views/popups/confirmPopup',

  'modules/shop.cash-register-retail/components/printing',
  'modules/common/components/promisify',
  'modules/shop.cash-register-retail/collections/routeCache',
], (
  $, _, Backbone, PopupView, Upx, Loader, Locale,
  CashRegisterApi, Moment, OnlineFoodOrderComponent,
  Timepicker, MessagePopupView, ConfirmPopupView,
  Printing, Promisify, RouteCache,
) => PopupView.extend({

  className() {
    const margin = this.options.model.get('status') === OnlineFoodOrderComponent.STATUS_NEW
      ? 'with-margin' : '';
    return `dialog dialog--food-order-event ${margin}`;
  },

  ui: {
    time: '[name="time"]',
    message: '[data-ui="message"]',
  },
  events: {
    // close
    'click [data-action=close]': 'closeClicked',
    'touchend [data-action=close]': 'closeClicked',

    'click [data-action=confirm]': 'confirmClicked',
    'touchend [data-action=confirm]': 'confirmClicked',
    'click [data-action=cancel]': 'cancelClicked',
    'touchend [data-action=cancel]': 'cancelClicked',
    'click [data-action=kitchen]': 'kitchenClicked',
    'touchend [data-action=kitchen]': 'kitchenClicked',
    'click [data-action=kitchen-with-confirm]': 'kitchenWithConfirmClicked',
    'touchend [data-action=kitchen-with-confirm]': 'kitchenWithConfirmClicked',
    'click [data-action=reprint]': 'reprintClicked',
    'touchend [data-action=reprint]': 'reprintClicked',

    'click [data-action=mark-picked-up]': 'pickedUpClicked',
    'touchend [data-action=mark-picked-up]': 'pickedUpClicked',
    'click [data-action=pay-order]': 'payOrderClicked',
    'touchend [data-action=pay-order]': 'payOrderClicked',
    'click [data-action=out-for-delivery]': 'outForDeliveryClicked',
    'touchend [data-action=out-for-delivery]': 'outForDeliveryClicked',
  },

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  callConfirm() {
    const time = this.ui.time.val();
    const def = Upx.call('OnlineFoodOrderModule', 'confirmOnlineOrder',
      {
        id: this.model.get('id'),
        time,
      });
    return def;
  },
  confirmClicked() {
    this.loader.showLoader();

    CashRegisterApi.logAction('FOOD_ORDER_CONFIRMED', {
      type: 'modules/shop.cash-register-retail/views/popups/onlineFoodOrder/foodOrderConfirmPopup',
      food_order_id: this.model.get('id'),
    });
    const def = this.callConfirm();
    def.always(() => {
      this.loader.hideLoader();
    });
    def.then(
      (order) => {
        this.model.set(order);
        this.close();
      },
      (error) => {
        this.showError(error);
      },
    );
  },

  kitchenWithConfirmClicked() {
    this.loader.showLoader();
    CashRegisterApi.logAction('FOOD_ORDER_IN_KITCHEN_WITH_CONFIRM', {
      type: 'modules/shop.cash-register-retail/views/popups/onlineFoodOrder/foodOrderConfirmPopup',
      food_order_id: this.model.get('id'),
    });
    const def = new $.Deferred();
    def.always(() => {
      this.loader.hideLoader();
    });

    this.callConfirm().then(
      () => this.callInKitchen().then(
        (order) => {
          this.model.set(order);
          this.close();

          this.printReceipts();
        },
        def.reject,
      ),
      def.reject,
    );
    def.fail(
      (error) => {
        this.showError(error);
      },
    );
  },
  callInKitchen() {
    const def = Upx.call('OnlineFoodOrderModule', 'markOnlineOrderAsInKitchen',
      {
        id: this.model.get('id'),
      });
    return def;
  },
  kitchenClicked() {
    this.loader.showLoader();
    CashRegisterApi.logAction('FOOD_ORDER_IN_KITCHEN', {
      type: 'modules/shop.cash-register-retail/views/popups/onlineFoodOrder/foodOrderConfirmPopup',
      food_order_id: this.model.get('id'),
    });
    const def = this.callInKitchen();
    def.always(() => {
      this.loader.hideLoader();
    });
    def.then(
      (order) => {
        this.model.set(order);
        this.close();

        this.printReceipts();
      },
      (error) => {
        this.showError(error);
      },
    );
  },

  pickedUpClicked() {
    this.loader.showLoader();
    CashRegisterApi.logAction('ONLINE_ORDER_BUTTON_CLICKED', {
      button: 'pickedUp',
      food_order_id: this.model.get('id'),
    });
    const def = this.ensureFinishedOrder();
    def.always(() => {
      this.loader.hideLoader();
    });
    def.then(
      (order) => {
        this.model.set(order);
        this.close();
      },
      (error) => {
        this.showError(error);
      },
    );
  },

  ensureFinishedOrder() {
    if (this.model.get('status') !== OnlineFoodOrderComponent.STATUS_FINISHED) {
      return Upx.call(
        'OnlineFoodOrderModule', 'finishOnlineOrder',
        {
          id: this.model.get('id'),
        },
      );
    }
    return new $.Deferred().resolve();
  },
  ensureShopOrder() {
    const def = new $.Deferred();
    if (!this.model.get('shop_order_id')) {
      Upx.call(
        'OnlineFoodOrderModule', 'newShopOrderForOnlineOrder',
        {
          id: this.model.get('id'),
        },
      ).then(
        () => {
          this.model.fetch().then(
            def.resolve,
            def.reject,
          );
        },
        def.reject,
      );
    } else {
      def.resolve();
    }
    return def.promise();
  },
  finishWithNewOrderCall() {
    const mainDef = new $.Deferred();
    const def = this.ensureFinishedOrder();
    def.then(
      (order) => {
        this.model.set(order);
        const shopDef = this.ensureShopOrder();
        shopDef.then(
          mainDef.resolve,
          mainDef.reject,
        );
      },
      mainDef.reject,
    );
    return mainDef;
  },
  payOrderClicked() {
    this.loader.showLoader();

    CashRegisterApi.logAction('ONLINE_ORDER_BUTTON_CLICKED', {
      button: 'payOrder',
      food_order_id: this.model.get('id'),
    });
    const mainDef = this.finishWithNewOrderCall();
    mainDef.always(() => {
      this.loader.hideLoader();
    });
    mainDef.then(
      () => {
        this.close();
        this.payOrder();
      },
      (error) => {
        this.showError(error);
      },
    );
  },

  outForDeliveryClicked() {
    this.loader.showLoader();
    CashRegisterApi.logAction('ONLINE_ORDER_BUTTON_CLICKED', {
      button: 'outForDelivery',
      food_order_id: this.model.get('id'),
    });
    const mainDef = this.finishWithNewOrderCall();
    mainDef.always(() => {
      this.loader.hideLoader();
    });
    mainDef.then(
      () => {
        this.close();
      },
      (error) => {
        this.showError(error);
      },
    );
  },

  payOrder() {
    const cacheId = RouteCache.createCache({
      orderId: this.model.get('shop_order_id'),
    });
    Backbone.history.navigate(`payment-screen/order/${cacheId}`, { trigger: true });
  },

  async printReceipts(reprint = false) {
    await this.printProduction(reprint);
    await this.printDelivery();
  },

  async printDelivery() {
    await Promisify.deferredToPromise(
      Printing.printOnlineFoodOrderDeliveryReceipt(
        this.getDataForProductionPrint(),
      ),
    );
  },

  printProduction(reprint = false) {
    return Promisify.deferredToPromise(
      Printing.printOnlineFoodOrderKitchenReceipt(
        this.getDataForProductionPrint(),
        {
          is_reprint: reprint,
        },
      ),
    );
  },

  reprintClicked() {
    this.loader.showLoader();
    this.printReceipts(true)
      .then(() => this.loader.hideLoader())
      .catch(() => this.loader.hideLoader());
  },

  showError(message) {
    new MessagePopupView().open(message);
  },

  cancelClicked() {
    this.close();
    setTimeout(
      () => {
        const view = new ConfirmPopupView();
        view.open(
          Locale.translate('are_you_sure_you_want_to_cancel_this_order'),
          Locale.translate('after_canceling_you_won_t_be_able_make_any_extra_changes_to_it'),
        ).then(
          () => {
            const def = Upx.call('OnlineFoodOrderModule', 'cancelOnlineOrder',
              {
                id: this.model.get('id'),
              });
            def.then(
              (order) => {
                CashRegisterApi.logAction('FOOD_ORDER_CANCELED', {
                  type: 'modules/shop.cash-register-retail/views/popups/onlineFoodOrder/foodOrderConfirmPopup',
                  food_order_id: this.model.get('id'),
                });

                this.model.set(order);
                this.close();
              },
              (error) => {
                this.showError(error);
              },
            );
          },
        );
      },
      100,
    );
  },
  getStartTime() {
    const asapTime = Moment().add(15, 'minute');
    let time = this.model.get('external_order.requestedAsap')
      ? asapTime
      : Moment(this.model.get('external_order.requestedTime'));

    if (time.isBefore(asapTime)) {
      time = asapTime;
    }
    const minDiff = time.minute() % 10;
    if (minDiff !== 0) {
      time.add(10 - minDiff, 'minute');
    }
    return time;
  },

  onRender() {
    const time = this.getStartTime();
    Timepicker.applyToEl(this.ui.time, {
      interval: 10,
      defaultTime: time.format('HH:mm'),
    });
  },

  open(def) {
    def = this.renderInFloatingRegion(def);

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/onlineFoodOrder/foodOrderConfirmPopup',
      food_order_id: this.model.get('id'),
    });

    this.openPopup();

    return def;
  },

  getDataForProductionPrint() {
    return this.getFormattedDataFromModel();
  },

  getFormattedDataFromModel() {
    throw new Error('getFormattedDataFromModel is not implemented');
  },

  serializeData() {
    const data = $.extend(
      {
        hasConfirmation: this.model.get('status') === OnlineFoodOrderComponent.STATUS_NEW,
        hasKitchen: this.model.get('status') === OnlineFoodOrderComponent.STATUS_CONFIRMED,
        hasFinish: this.model.get('status') === OnlineFoodOrderComponent.STATUS_IN_KITCHEN,
      },
      this.getFormattedDataFromModel(),
    );
    data.hasKitchenOnConfirm = data.hasConfirmation;
    data.hasCancel = data.hasConfirmation || data.hasKitchen || data.hasFinish;
    data.hasPayBtn = this.model.get('status') === OnlineFoodOrderComponent.STATUS_FINISHED && !data.isPaid;
    data.isComplete = this.model.get('status') === OnlineFoodOrderComponent.STATUS_FINISHED
      || this.model.get('status') === OnlineFoodOrderComponent.STATUS_CANCELED;
    let count = 0;
    [
      data.hasConfirmation,
      data.hasKitchenOnConfirm,
      data.hasKitchen,
      data.hasFinish,
      data.hasFinish, // finish has also reprint
      data.hasCancel,
      data.hasPayBtn,
    ].map((v) => (v ? count += 1 : 0));
    data.btnCount = count;
    return data;
  },
}));
