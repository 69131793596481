define([
  'jquery',
  'underscore',
  'backbone',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/orderShippingPopup.hbs',

  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/shop.common/collections/upx/ProviderMethodType',
  'modules/common/components/currency',
  'modules/shop.cash-register-retail/components/shippingMethod.js',

  'modules/shop.cash-register-retail/collections/routeCache',
  'modules/common/components/historyBreadcrumb',
  'modules/common/components/locale',

  'modules/shop.cash-register-retail/components/printing',
  'modules/shop.cash-register-retail/models/settings/receiptPrinter',
  'modules/admin/behaviors/loader',
], (
  $, _, Backbone, PopupView, Template,
  CashRegisterApi, ProviderMethodTypeCollection, Currency, ShippingMethod,
  RouteCache, HistoryBreadcrumb, Locale,
  PrintingComponent, ReceiptPrinter, Loader,
) => PopupView.extend({
  template: Template,

  className: 'dialog dialog--order-shipping',

  ui: {
    close: '[data-action="close"]',
  },
  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  events: {
    'click @ui.close': 'closeClicked',
    'click [data-action=collect]': 'collectClicked',
    'click [data-action="pay"]': 'payClicked',
    'click [data-action="customer"]': 'customerClicked',
    'click [data-action="deliver"]': 'deliverClicked',
    'click [data-action="print"]': 'printClicked',
  },
  customerClicked() {
    this.close();
    HistoryBreadcrumb.goto(`customers/details/${this.model.get('relation_data_id')}`);
  },

  collectClicked() {
    this.close();
    this.triggerMethod('order:pickup');
  },
  deliverClicked(ev) {
    const shippingId = $(ev.currentTarget).data('id');
    this.close();
    this.triggerMethod('shipment:deliver', shippingId);
  },

  printClicked(ev) {
    const def = this.loader.startLoader();
    const shipmentId = $(ev.currentTarget).data('id');
    PrintingComponent
      .printOrderShipmentContents(this.model, shipmentId)
      .then(def.resolve, def.reject);
  },

  open(def) {
    def = this.renderInFloatingRegion(def);
    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/orderShippingPopup',
    });

    this.openPopup();

    const myRoute = `open-orders/order/${this.model.get('id')}`;
    HistoryBreadcrumb.replaceCurrent(myRoute);

    def.always(
      () => {
        if (HistoryBreadcrumb.getCurrent() === myRoute) {
          HistoryBreadcrumb.replaceCurrent('open-orders');
        }
      },
    );

    return def;
  },

  payClicked() {
    const orderId = this.model.get('id');
    const cacheId = RouteCache.createCache({
      orderId,
      backRoute: `open-orders/order/${orderId}`,
    });
    HistoryBreadcrumb.goto(`payment-screen/order/${cacheId}`);
    this.closeClicked();
  },

  serializeData() {
    const data = this.model.toJSON();
    const paymentCollection = this.model.getPaymentItemCollection();
    const payments = [];
    if (paymentCollection.length > 0) {
      paymentCollection.each((paymentModel) => {
        if (paymentModel.get('payment.date_paid') && !paymentModel.get('payment.is_virtual')) {
          const id = paymentModel.get('payment.provider_method.provider_method_type_id');
          const name = ProviderMethodTypeCollection.getNameById(id);
          const amount = Currency.format(paymentModel.get('payment.currency_iso3'), paymentModel.get('payment.amount'));
          // Loyalty points does not have payment.method
          const paymentMethod = paymentModel.has('payment.method') ? ` ${paymentModel.get('payment.method')} ` : ' ';
          payments.push(`${name}:${paymentMethod}(${amount})`);
        }
      });
    }

    const products = [];
    const orderItems = this.model.getOrderItemCollection();

    orderItems.each((model) => {
      const isFulfilled = model.get('unfulfilled_quantity') === 0 && model.get('quantity') !== 0;
      if (model.get('is_product') && !isFulfilled) {
        products.push({
          quantity: model.get('quantity'),
          name: model.get('name'),
          sku: model.get('sku'),
          unfulfilled_quantity: model.get('unfulfilled_quantity'),
          imageUrl: model.get('flat_product.main_image.url'),
          subitems: model.get('subitems') || [],
        });
      }
    });

    data.products = products;
    data.shipping = {
      name: ShippingMethod.getNameForOrder(this.model),
      icon: ShippingMethod.getIconForOrder(this.model),
    };
    data.payments = payments.join(', ');
    data.value_outstanding_wt = this.model.get('value_wt') - this.model.get('paid_value_wt')
        + this.model.get('paid_back_value_wt');
    data.shipment_status = this.getShipmentStatusLabel(data);
    data.shipment_label = this.getShipmentStatusClassName(data);

    const shipments = [];
    const shipmentCollection = this.model.getShipmentItemCollection();
    shipmentCollection.each((model) => {
      const icon = ShippingMethod.getIconForShipment(model);
      const inDelivered = ShippingMethod.isShipmentDelivered(model);
      shipments.push({
        id: model.get('id'),
        icon,
        name: model.get('shipping_method.name'),
        status_name: ShippingMethod.getStatusName(model.get('shipment_status_type.alias')),
        status_label: ShippingMethod.getStatusLabelClass(model.get('shipment_status_type.alias')),
        shipment_items: model.get('shipment_items'),
        showDeliverInStore: !inDelivered,
      });
    });
    data.shipments = shipments;
    data.hasReceiptPrinter = ReceiptPrinter.isWantedType();

    data.isPickup = this.model.isPickup();
    return data;
  },

  getShipmentStatusLabel({ item_no, shipped_item_no, delivered_item_no }) {
    if (delivered_item_no === item_no) {
      return Locale.translate('fully_delivered');
    }
    if (shipped_item_no === 0) {
      return Locale.translate('not_send');
    }
    return Locale.translate('{shipped_item_no}_out_of_{item_no}_sent_and_{delivered_item_no}_out_of_{shipped_item_no}_delivered', {
      item_no,
      shipped_item_no,
      delivered_item_no,
    });
  },

  getShipmentStatusClassName({ item_no, shipped_item_no, delivered_item_no }) {
    if (delivered_item_no === item_no) {
      return 'success';
    }
    if (shipped_item_no === 0) {
      return 'danger';
    }
    return 'warning';
  },
}));
