define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/popups/orderDetailsPopup/payments.hbs',
], (
  $, _, Backbone, Template,
) => Backbone.Marionette.LayoutView.extend({
  template: Template,

  className: 'payment-list-table',

  initialize(options) {
    this.payments = options.payments;
  },

  getPayments() {
    return this.payments.map((payment) => {
      // When the date_paid is set. we assume the payment/refund has been made.
      payment.is_paid = !!payment.date_paid;

      // return object
      return payment;
    });
  },

  serializeData() {
    return {
      payments: this.getPayments(),
    };
  },

}));
