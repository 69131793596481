define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/popups/chooseOpenOrder/collection.hbs',

  './item',
], (
  $, _, Backbone, Template,
  ItemView,
) => Backbone.Marionette.CompositeView.extend({

  template: Template,

  className: 'receipt-list',

  childView: ItemView,

  childEvents: {
    'child:clicked': 'childClicked',
  },

  childClicked(view, model) {
    this.triggerMethod('child:clicked', model);
  },

  childViewContainer: 'tbody',

  events: {
    'click [data-action="more"]': 'moreClicked',
  },

  ui: {
    more: '[data-ui="more"]',
  },

  childViewOptions(model) {
    return {
      model,
      currentOrder: this.options.currentOrder,
      hasIcl: this.options.hasIcl,
    };
  },

  moreClicked() {
    if (this.collection.canFetchNext) {
      const def = this.collection.fetchNext();
      this.triggerMethod('loader:start', def);
    }
  },

  onShow() {
    this.collection.on('all', this.checkFetchNext, this);
  },

  onDestroy() {
    this.collection.off('all', this.checkFetchNext, this);
  },

  checkFetchNext: _.debounce(function () {
    if (!this.isDestroyed) {
      if (this.collection.canFetchNext()) {
        this.ui.more.show();
      } else {
        this.ui.more.hide();
      }
    }
  }, 100),
}));
