define([
  'jquery',
  'underscore',
  'backbone',

  './singleChoice',
  './multipleChoice',
  './empty',
], (
  $, _, Backbone,
  SingleChoiceView, MultipleChoiceView, EmptyView,
) => Backbone.Marionette.CollectionView.extend({

  initialize() {
    this.selection = this.options.selection;
  },

  childViewOptions() {
    return {
      selection: this.selection,
    };
  },

  getChildView(model) {
    if (model.get('product_addon_group.type') === 'single-choice') {
      return SingleChoiceView;
    } if (model.get('product_addon_group.type') === 'multiple-choice') {
      return MultipleChoiceView;
    }
    // fallback
    console.warn(`Found unknown product_addon_group type ${model.get('product_addon_group.type')}`);
    return SingleChoiceView;
  },

  emptyView: EmptyView,

}));
