define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/popups/openCCVPinTransactionPopup/recoveryFailed.hbs',
], (
  $, _, Backbone, Template,
) => Backbone.Marionette.LayoutView.extend({
  template: Template,

  initialize(options) {
    this.error = options.error && options.error.error;
  },

  serializeData() {
    return {
      error: this.error,
    };
  },
}));
