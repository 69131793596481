define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/views/swappableWithLoading',

  './form',
  '../hospitalityMembercardPopup/createCustomer',
], (
  $, _, Backbone, SwappableView,
  FormView, CreateCustomerView,
) => SwappableView.extend({

  initialize(options) {
    SwappableView.prototype.initialize.call(this, options);
    const {
      closeDef, attachCustomer, createCustomer, initialBalance,
    } = options;
    this.setMainView(FormView, {
      closeDef,
      attachCustomer,
      createCustomer,
      initialBalance,
    });
    this.setView('createCustomer', CreateCustomerView);
  },

}));
