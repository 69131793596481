define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/popups/openCCVPinTransactionPopup/recoveringTransaction.hbs',
  'modules/common/components/locale',
  'toastr',

  'modules/admin/behaviors/loader',
  'modules/shop.cash-register-retail/components/cashRegisterApi',

  'modules/shop.cash-register-retail/views/payments/cashierDisplay',
  'modules/shop.cash-register-retail/models/settings/receiptPrinter',

  'modules/shop.cash-register-retail/models/ccv/ccvPaymentHandler',
  'modules/shop.cash-register-retail/models/ccv/openCCVPinTransaction',
  'modules/shop.cash-register-retail/components/paymentAttachment',
  'modules/shop.cash-register-retail/components/printing',

  'modules/shop.cash-register-retail/components/paymentMethodItem',
  'modules/shop.cash-register-retail/components/pinReceipt',

  'upx.modules/ShopModule/models/Order',
  'upx.modules/BillingModule/models/Invoice',
], (
  $, _, Backbone, Template, Locale, Toastr,
  Loader, CashRegisterApi,
  CashierDisplayView, ReceiptPrinterModel,
  CCVPaymentHandler, OpenCCVPinTransaction, PaymentAttachment, Printing,
  PaymentMethodItemComponent, PinReceiptComponent,
  OrderModel, InvoiceModel,
) => Backbone.Marionette.LayoutView.extend({
  template: Template,

  regions: {
    'cashier-display': '[data-region=cashier-display]',
  },

  initialize(options) {
    this.transaction = options.transaction;
    this.close = options.close;

    this.handler = new CCVPaymentHandler({
      trx: this.transaction.trx,
    });
  },

  startRecovery() {
    const cashierDisplay = this.renderCashierDisplay();

    cashierDisplay.on('cancel:click', () => {
      this.handler.cancelRecovery().then(() => {
        Toastr.warning(Locale.translate('cancelled_pin_transaction_recovery'));
        this.triggerMethod('layout:swap');
      }, (error) => {
        const toastMessage = error.error || Locale.translate('an_error_occurred');
        Toastr.error(toastMessage);
      });
    });

    this.handler.on(this.handler.EVENT_IPC_CONNECTION_LOST, () => {
      this.recoveryFailed({
        error: Locale.translate('lost_connection_to_external_process'),
      });
    });

    cashierDisplay.toggleCancelButton(true);
    this.handler.startRecovery(cashierDisplay, true).then((result) => {
      // Once a result has been received from recovery,
      // you should not be able to use the cancel button
      cashierDisplay.toggleCancelButton(false);

      this.attachPayment().then((attachSuccess) => {
        if (result === this.handler.RESULT_SUCCESS) {
          // The transaction was actually successful,
          // but we still need to make sure the result was actually received in the backend.
          cashierDisplay.updateDisplay(
            Locale.translate('verifying_payment_dot_dot_dot').toUpperCase(),
          );

          this.handler.verifyPayment(this.transaction.id)
            .then(() => {
              const printDef = this.printFullReceiptOrPinReceipt(cashierDisplay, attachSuccess);

              printDef.always(() => {
                this.recoverySuccess();
              });
            }, (error) => {
              this.recoveryFailed(error);
            });
        } else {
          const printDef = this.printFullReceiptOrPinReceipt(cashierDisplay, false);

          printDef.always(() => {
            this.recoverySuccess();
          });
        }
      }, (error) => {
        this.recoveryFailed(error);
      });
    }, (error) => {
      this.recoveryFailed(error);
    });
  },

  printFullReceiptOrPinReceipt(cashierDisplay, fullReceipt = true) {
    // CCV also requires us to print the receipt after
    // the transaction was successfully recovered.
    let printDef;
    if (fullReceipt) {
      printDef = this.printReceipt(cashierDisplay);
    } else {
      // The attaching of the payment to the order may have failed.
      // This means the recovery was successful, but the attaching not.
      // Because printing a full receipt requires the payment to be attached to the order,
      // we just print the pin receipt.
      printDef = this.printPinReceipt(cashierDisplay);
    }

    printDef.fail(() => {
      // It does not really matter if the receipt fail
      // to print because it can always be reprinted later.
      Toastr.error(Locale.translate('could_not_print_receipt'));
    });

    return printDef;
  },

  attachPayment() {
    const def = $.Deferred();

    const paymentIds = [this.transaction.id];
    if (this.transaction.orderId !== undefined) {
      PaymentAttachment.scheduleAndAwaitAttachPaymentsToOrder({
        orderId: this.transaction.orderId,
        paymentIds,
      }).then(() => def.resolve(true)).catch(() => def.resolve(false));
    } else if (this.transaction.invoiceId !== undefined) {
      PaymentAttachment.scheduleAndAwaitAttachPaymentsToInvoice({
        invoiceId: this.transaction.invoiceId,
        paymentIds,
      }).then(() => def.resolve(true)).catch(() => def.resolve(false));
    } else {
      // There is no order to attach the payment to
      def.resolve();
    }

    return def;
  },

  printPinReceipt(cashierDisplay) {
    const def = $.Deferred();

    cashierDisplay.updateDisplay(
      Locale.translate('checking_for_pin_receipt').toUpperCase(),
    );

    PaymentMethodItemComponent.getPinReceiptFromPaymentId(this.transaction.id).then(
      (pinReceipt) => {
        cashierDisplay.updateDisplay(
          Locale.translate('printing_pin_receipt_dot_dot_dot').toUpperCase(),
        );
        const ccvReceipts = PinReceiptComponent.getCCVPinReceipts(pinReceipt);
        if (ccvReceipts != null) {
          Printing.printCCVReceipt(ccvReceipts).then(def.resolve, def.reject);
        } else {
          // There is no pin receipt
          def.resolve();
        }
      },
    );

    return def;
  },

  printReceipt(cashierDisplay) {
    const def = $.Deferred();

    const hasPrint = this.transaction.orderId !== undefined
        || this.transaction.invoiceId !== undefined;
    if (hasPrint) {
      cashierDisplay.updateDisplay(
        Locale.translate('printing_receipt_dot_dot_dot').toUpperCase(),
      );

      if (this.transaction.orderId !== undefined) {
        const orderModel = new OrderModel({ id: this.transaction.orderId });
        orderModel.fetch().then(() => {
          Printing.printReceipt(orderModel).then(def.resolve, def.reject);
        }, def.reject);
      } else if (this.transaction.invoiceId !== undefined) {
        const invoiceModel = new InvoiceModel({
          id: this.transaction.invoiceId,
          get_rows: 'true',
          get_taxes: 'true',
        });
        invoiceModel.fetch().then(() => {
          Printing.printInvoiceReceipt(invoiceModel).then(def.resolve, def.reject);
        }, def.reject);
      }
    } else {
      // There is nothing to print, so just resolve
      def.resolve();
    }

    return def;
  },

  recoverySuccess() {
    const def = OpenCCVPinTransaction.loadOpenTransaction();

    def.fail(() => {
      Toastr.warning(
        Locale.translate('error_reloading_open_transaction'),
        Locale.translate('please_reload_the_page'),
      );
    });

    def.always(() => {
      Toastr.success(Locale.translate('pin_transaction_successfully_recovered'));
      this.close();
    });
  },

  recoveryFailed(error) {
    this.triggerMethod('layout:swap', 'recoveryFailed', {
      error,
    });
  },

  onShow() {
    this.startRecovery();
  },

  renderCashierDisplay() {
    const cashierDisplay = new CashierDisplayView();
    cashierDisplay.updateDisplay(
      Locale.translate('recovering_payment_dot_dot_dot').toUpperCase(),
      Locale.translate('this_may_take_a_while').toUpperCase(),
    );
    cashierDisplay.showDisplay(true);

    this.getRegion('cashier-display').show(cashierDisplay);

    return cashierDisplay;
  },

}));
