define([
  'jquery',
  'underscore',
  'backbone',
  './choice',
  'modules/shop.cash-register-retail/templates/popups/productAddonPopup/singleChoice',
], (
  $, _, Backbone, ChoiceView, Template,
) => ChoiceView.extend({

  template: Template,

  ui: {
    select: 'select',
  },

  events: {
    'change select': 'selectChanged',
  },

  selectChanged() {
    this.removeSelection(this.currentSelection);
    const value = this.ui.select.val();
    this.addSelection(value);
    this.currentSelection = value;
  },

}));
