define([
  'jquery',
  'backbone',
  'underscore',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/productCacheSettingSyncPopup.hbs',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'toastr',

  'modules/shop.cash-register-retail/components/productCache',
], (
  $, Backbone, _, PopupView, Template, Locale, CashRegisterApi, Toastr,
  ProductCache,
) => PopupView.extend({
  template: Template,

  className: 'dialog',

  ui: {
    close: '[data-action="close"]',
    title: '[data-ui="title"]',
    stopSync: '[data-ui="stop-sync"]',
  },

  events: {
    'click @ui.close': 'closeClicked',
    'touchend @ui.close': 'closeClicked',

    'click @ui.stopSync': 'stopSyncClicked',
    'touchend @ui.stopSync': 'stopSyncClicked',
  },

  initialize() {
    this.syncState = {};
    this.syncNewDone = false;
    this.syncUpdateDone = false;
    this.title = '';

    ProductCache.on('sync:progress', this.syncProgress, this);

    ProductCache.on('sync:ended', this.syncEnded, this);
  },

  syncEnded(type) {
    if (type === 'new') {
      this.syncNewDone = true;
    } else {
      this.syncUpdateDone = true;
    }

    this.renderDebounce();
  },

  syncProgress(syncState) {
    this.syncState = syncState;
    this.renderDebounce();
  },

  onDestroy() {
    ProductCache.off('sync:progress', this.syncProgress, this);
    ProductCache.off('sync:ended', this.syncEnded, this);
  },

  stopSyncClicked() {
    ProductCache.stopForcedSync();
    this.closeClicked();
  },

  serializeData() {
    const {
      newProductSyncTotal,
      newProductSyncProgress,
      updateProductSyncTotal,
      updateProductSyncProgress,
    } = this.syncState;

    const syncNewPercentage = newProductSyncProgress
      ? ((newProductSyncProgress / newProductSyncTotal) * 100).toFixed(2)
      : 0;
    const syncUpdatePercentage = updateProductSyncProgress
      ? ((updateProductSyncProgress / updateProductSyncTotal) * 100).toFixed(2)
      : 0;

    return {
      ...this.syncState,
      new_product_sync_total: newProductSyncTotal,
      update_product_sync_total: updateProductSyncTotal,
      title: this.title,
      syncNewPercentage,
      syncUpdatePercentage,
      syncNewDone: this.syncNewDone,
      syncUpdateDone: this.syncUpdateDone,
    };
  },

  renderDebounce: _.debounce(function () {
    if (!this.isDestroyed) {
      this.render();
    }
  }, 250),

  open(title, def) {
    def = this.renderInFloatingRegion(def);

    this.title = title;

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/productCacheSettingSyncPopup',
      title,
    });

    this.openPopup();

    if (ProductCache.isSynching) {
      // ensure forced
      ProductCache.startForcedSync();
      this.syncState = ProductCache.syncState;
      this.renderDebounce();
    } else {
      ProductCache.runCron(true);
    }

    return def;
  },

}));
