define([
  'jquery',
  'underscore',
  'backbone',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/membercardPopup',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/views/keypads/main',
  'modules/shop.cash-register-retail/components/cashRegisterApi',

  'modules/shop.cash-register-retail/models/keyboard',
], (
  $, _, Backbone, PopupView, Template, Locale, PercentageKeypad, CashRegisterApi,
  KeyboardModel,
) => PopupView.extend({
  template: Template,

  className: 'dialog dialog--membercard',

  ui: {
    close: '[data-action="close"]',
    success: '[data-action="success"]',
    title: '[data-ui="title"]',
    code: '[data-ui="code"]',
    accept: '[data-ui="accept"]',
  },

  events: {
    'click @ui.close': 'closeClicked',
    'touchend @ui.close': 'closeClicked',

    'click @ui.accept': 'success',
    'touchend @ui.accept': 'success',
  },

  regions: {
    keypad: '[data-region="keypad"]',
  },

  initialize(options) {
    PopupView.prototype.initialize.call(this, options);
    this.keyboardModel = KeyboardModel;
  },

  onRender() {
    const region = this.getRegion('keypad');
    const view = new PercentageKeypad();
    region.show(view);
  },

  open(def, title) {
    title = title || Locale.translate('scan_new_customer_card');
    def = this.renderInFloatingRegion(def);

    this.ui.title.text(title);

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/membercardPopup',
      title,
    });

    this.openPopup();

    this.keyboardModel.membercardCodeMode(this.cid);
    return def;
  },

  changeMode() {
    this.stopListening();
    if (
      (this.keyboardModel.get('mode') === this.keyboardModel.MODE_MEMBERCARD_CODE && this.keyboardModel.get('viewId') === this.cid)
    ) {
      this.startListening();
    }
  },

  startListening() {
    this.stopListening();
    this.keyboardModel.on('key:pressed', this.handleKeyPress, this);
    this.keyboardModel.on('key:confirm', this.handleConfirmation, this);
    this.keyboardModel.on('key:backspace', this.handleBackspace, this);
    this.keyboardModel.on('key:cancel', this.handleCancel, this);
    if (!_.isString(this.ui.code)) {
      this.ui.code.addClass('element-focus');
    }
  },

  stopListening() {
    this.keyboardModel.off('key:pressed', this.handleKeyPress, this);
    this.keyboardModel.off('key:confirm', this.handleConfirmation, this);
    this.keyboardModel.off('key:backspace', this.handleBackspace, this);
    this.keyboardModel.off('key:cancel', this.handleCancel, this);
    if (!_.isString(this.ui.code)) {
      this.ui.code.removeClass('element-focus');
    }
  },

  handleKeyPress(key) {
    const el = this.ui.code;
    const regex = /[a-zA-Z0-9]/;
    if (!_.isString(el) && regex.test(key)) {
      let value = el.val();
      value += key.toUpperCase();
      el.val(value);
    }
  },

  isUsableKey(key) {
    const int = parseInt(key);
    const isNumber = !_.isNaN(int) && _.isNumber(int);
    const isComma = key === ',';
    return isComma || isNumber;
  },

  handleConfirmation() {
    this.success();
  },

  handleBackspace() {
    let value = this.ui.code.val() || '';
    value = value.substr(0, value.length - 1);
    this.ui.code.val(value);
  },

  handleCancel() {
    this.ui.code.val('');
  },

  close() {
    this.keyboardModel.resetMode();
    PopupView.prototype.close.apply(this);
  },

  success: _.debounce(function () {
    const code = this.ui.code.val();

    if (!_.isEmpty(code)) {
      this.closeDef.resolve({
        code,
      });
      this.close();
    }
  }, 101),

  onShow() {
    this.keyboardModel.on('change', this.changeMode, this);
  },

  onDestroy() {
    this.keyboardModel.off('change', this.changeMode, this);
    this.keyboardModel.resetMode();
    this.close();
  },

}));
