define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/views/swappableWithLoading',

  './openTransaction',
  './recoveringTransaction',
  './recoveryFailed',
], (
  $, _, Backbone, SwappableView,
  OpenTransactionView, RecoveringTransactionView, RecoveryFailedView,
) => SwappableView.extend({

  initialize(options) {
    SwappableView.prototype.initialize.call(this, options);

    this.setMainView(OpenTransactionView, options);
    this.setView('recoveringTransaction', RecoveringTransactionView, options);
    this.setView('recoveryFailed', RecoveryFailedView, options);
  },

}));
