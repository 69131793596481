define([
  'jquery',
  'underscore',
  'backbone',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/chooseOpenOrder.hbs',
  'modules/common/components/locale',

  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/admin/behaviors/loader',

  'upx.modules/ShopModule/collections/Order',
  'modules/shop.cash-register-retail/views/popups/chooseOpenOrder/collection.js',
  'modules/shop.cash-register-retail/views/popups/messagePopup',

], (
  $, _, Backbone, PopupView, Template, Locale,
  CashRegisterApi, Loader,
  OrderCollection, CollectionView, MessagePopupView,

) => PopupView.extend({
  template: Template,

  className: 'dialog dialog--category',

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  ui: {
    close: '[data-action="close"]',
    success: '[data-action="success"]',
    title: '[data-ui="title"]',
    code: '[data-ui="code"]',
    accept: '[data-ui="accept"]',
  },

  events: {
    'click @ui.close': 'closeClicked',
    'touchend @ui.close': 'closeClicked',

    'click @ui.accept': 'success',
    'touchend @ui.accept': 'success',
  },

  regions: {
    orders: '[data-region=orders]',
  },

  renderContent(customerId, currentOrder, hasIcl, def) {
    const loaderDef = this.loader.startLoader();
    const params = {
      start: 0,
      limit: 25,
      sort: [{
        name: 'date_created',
        dir: 'desc',
      }],
      filters: [
        {
          name: 'relation_data_id__=',
          val: customerId,
        },
        {
          name: 'is_anonymous__=',
          val: '0',
        },
        {
          name: 'status__in_list',
          multi_val: [
            'new',
            'processing',
          ],
        },
        {
          name: 'invoiced_price_wt__=',
          val: '0',
        },
        {
          name: 'order_item_tax_rate_id__has_special_community_intra',
          val: hasIcl ? '1' : '0',
        },
      ],
    };

    const region = this.getRegion('orders');
    const collection = new OrderCollection();
    collection.fetchAll({ params }).then(
      () => {
        collection.unshift(currentOrder);

        const view = new CollectionView({
          collection,
          currentOrder,
          hasIcl,
        });
        view.on('child:clicked', (model) => {
          def.resolve(model);
        });
        region.show(view);
        loaderDef.resolve();
      },
      () => {
        (new MessagePopupView()).open(Locale.translate('failed_to_fetch_open_orders'));
        loaderDef.reject();
      },

    );
  },

  open(customerId, currentOrder, hasIcl) {
    const def = this.renderInFloatingRegion();

    const title = Locale.translate('choose_open_order');
    this.ui.title.text(title);

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/chooseOpenOrder',
      title,
      customerId,
      hasIcl,
    });

    this.openPopup();

    this.renderContent(customerId, currentOrder, hasIcl, def);

    return def;
  },

}));
