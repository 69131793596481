define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/popups/calendarEventPopup/treatments/product.hbs',

  'modules/common/components/locale',

  'modules/shop.cash-register-retail/collections/upx/FeaturedAttribute',
], (
  $, _, Backbone, Template,
  Locale,
  FeaturedAttributeCollection,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'treatments__category__product',

  initialize(options) {
    this.appointmentModel = options.appointmentModel;
  },

  serializeData() {
    const data = this.model.toJSON();
    data.duration_in_minutes = this.getDurationInMinutes();
    data.duration_in_seconds = this.getDurationInSeconds();
    return data;
  },

  getDurationInMinutes() {
    let duration = 0;
    const wantedAttribute = FeaturedAttributeCollection.getAttributeIdByAlias(FeaturedAttributeCollection.ALIAS_DURATION_IN_SECONDS);
    const contentVars = this.model.get('flat_product.content_vars') || [];
    contentVars.forEach((item) => {
      if (wantedAttribute === item.attribute_id) {
        duration = parseInt(item.value) / 60;
      }
    });
    return duration;
  },

  getDurationInSeconds() {
    let duration = 0;
    const wantedAttribute = FeaturedAttributeCollection.getAttributeIdByAlias(FeaturedAttributeCollection.ALIAS_DURATION_IN_SECONDS);
    const contentVars = this.model.get('flat_product.content_vars') || [];
    contentVars.forEach((item) => {
      if (wantedAttribute === item.attribute_id) {
        duration = parseInt(item.value);
      }
    });
    return duration;
  },

  events: {
    'click [data-action="add"]': 'addClicked',
  },

  addClicked() {
    const orderItems = this.appointmentModel.get('order.order_items') || [];

    orderItems.push({
      ppu_wt: this.model.get('product_price.ppu_wt'),
      quantity: 1,
      sku: this.model.get('flat_product.product.sku'),
      name: this.model.get('flat_product.title'),
      extra: {
        duration_in_minutes: this.getDurationInMinutes(),
        duration_in_seconds: this.getDurationInSeconds(),
      },
      shop_product_id: this.model.get('id'),
    });

    this.model.unset('order.order_items', { silent: true });
    this.appointmentModel.set({
      'order.order_items': orderItems,
    }, { silent: true });
    this.appointmentModel.trigger('order_item_added');
    this.appointmentModel.calculateEndDate();
  },

}));
