define([
  'jquery',
  './actionPopup',
  'modules/shop.cash-register-retail/templates/popups/onlineFoodOrder/siteDishActionPopup.hbs',

  'modules/shop.cash-register-retail/components/onlineFoodOrder',
], (
  $, PopupView, Template,
  OnlineFoodOrderComponent,
) => PopupView.extend({
  template: Template,

  getFormattedDataFromModel() {
    return OnlineFoodOrderComponent.getSitedishDataFromModel(this.model);
  },
}));
