define([
  'jquery',
  'underscore',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/cashDrawerPopup',

  'modules/shop.cash-register-retail/views/keypads/main',
  'modules/common/components/locale',

  'modules/shop.cash-register-retail/models/keyboard',
  'modules/shop.cash-register-retail/components/toaster',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/shop.cash-register-retail/views/inputs/currencyInput',
  'modules/shop.cash-register-retail/views/keypads/main',

  'modules/shop.cash-register-retail/models/keyboard',
  'modules/shop.cash-register-retail/models/settings/receiptPrinter',
], (
  $, _, PopupView, Template,
  KeypadPercentageView, Locale,
  KeyboardModel, Toaster, CashRegisterApi, CurrencyInput, KeypadView,
  Keyboard, ReceiptPrinter
) => PopupView.extend({
  template: Template,

  className: 'dialog dialog--cash-drawer',

  ui: {
    note: '[data-ui="note"]',
    input: '[data-ui="input"]',
    in: '[data-ui="in"]',
    out: '[data-ui="out"]',
  },

  events: {
    'click [data-action="close"]': 'closeClicked',
    'touchend [data-action="close"]': 'closeClicked',

    'click [data-action="in"]': 'inClicked',
    'touchend [data-action="in"]': 'inClicked',

    'click [data-action="out"]': 'outClicked',
    'touchend [data-action="out"]': 'outClicked',

    'click [data-action="confirm"]': 'handleConfirmation',
    'touchend [data-action="confirm"]': 'handleConfirmation',

    'click [data-action="confirm_print"]': 'handlePrintConfirmation',
    'touchend [data-action="confirm_print"]': 'handlePrintConfirmation',

    'focus [data-action="note"]': 'noteFocus',
  },

  regions: {
    keypad: '[data-region="keypad"]',
    popup: '[data-region="popup"]',
    cash: '[data-region="cash"]',
  },

  initialize() {
    this.direction = 'in';
  },

  inClicked() {
    Keyboard.trigger('key:cancel');
    this.$el.find('.element-focus').removeClass('element-focus');
    const $el = this.ui.in;
    $el.addClass('element-focus');
    this.direction = 'in';
  },

  outClicked() {
    Keyboard.trigger('key:cancel');
    this.$el.find('.element-focus').removeClass('element-focus');
    const $el = this.ui.out;
    $el.addClass('element-focus');
    this.direction = 'out';
  },

  noteFocus() {
    Keyboard.trigger('key:cancel');
  },

  handleConfirmation: _.debounce(function () {
    this.viewDef.resolve({
      direction: this.direction,
      amount: this.cashView.getValue(),
      note: this.ui.note.val(),
      print: false,
    });

    $.magnificPopup.close();
  }, 100),

  handlePrintConfirmation: _.debounce(function () {
    this.viewDef.resolve({
      direction: this.direction,
      amount: this.cashView.getValue(),
      note: this.ui.note.val(),
      print: true,
    });
    $.magnificPopup.close();
  }, 100),

  open(viewDef, direction, amount) {
    viewDef = this.renderInFloatingRegion(viewDef);

    if (direction) {
      if (direction === 'in') {
        this.inClicked();
      } else if (direction === 'out') {
        this.outClicked();
      }
    }
    if (!_.isString(this.ui.input) && amount) {
      this.cashView.setValue(amount);
    }

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/cashDrawerPopup',
      direction,
      amount,
    });

    this.openPopup({
      mainClass: 'mfp-fade dialog--cash-popup',
      prependTo: $('#main').get(0), // Needed to be able to scroll to note input when keyboard shows
    });

    this.viewDef = viewDef;
  },

  onRender() {
    this.renderKeyboard();
    this.renderCash();
  },

  renderCash() {
    const region = this.getRegion('cash');
    this.cashView = new CurrencyInput();
    region.show(this.cashView);
  },

  renderKeyboard() {
    const region = this.getRegion('keypad');
    const view = new KeypadView();
    region.show(view);
  },

  closeClicked: _.debounce(function () {
    Toaster.warning(Locale.translate('cancelled_cash_drawer_mutation'));
    this.close();
  }, 100),

  serializeData() {
    return {
      has_receipt_printer: ReceiptPrinter.isWantedType()
    };
  },

}));
