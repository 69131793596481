define([
  'jquery',
  'underscore',
  'backbone',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/cashAmountPopup',

  'modules/common/components/locale',
  'modules/common/components/currency',

  'modules/shop.cash-register-retail/models/upx/DefaultShopConfiguration',
  'modules/shop.cash-register-retail/models/settings/receiptPrinter',
  'modules/shop.cash-register-retail/models/keyboard',

  'modules/shop.cash-register-retail/views/keypads/main',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
], (
  $, _, Backbone, PopupView, Template,
  Locale, Currency,
  DefaultShopConfigurationModel, ReceiptPrinterModel, KeyboardModel,
  KeypadView, CashRegisterApi,
) => PopupView.extend({
  template: Template,

  className: 'dialog dialog--cash-amount',

  ui: {
    quantity: '[data-quantity]',
  },

  events: {
    'click [data-action="close"]': 'closeClicked',
    'touchend [data-action="close"]': 'closeClicked',

    'click [data-action="drawer"]': 'drawerClicked',
    'touchend [data-action="drawer"]': 'drawerClicked',

    'click [data-action="apply"]': 'applyClicked',
    'touchend [data-action="apply"]': 'applyClicked',

    'click [data-quantity]': 'quantityClicked',
    'touchend [data-quantity]': 'quantityClicked',
    'focus [data-quantity]': 'quantityClicked',

    'keyup [data-quantity]': 'quantityChanged',
  },

  regions: {
    keypad: '[data-region=keypad]',
  },

  quantityClicked(ev) {
    const $el = $(ev.currentTarget);
    $el.prop('selectionStart', 0).prop('selectionEnd', 0); // Ensures the text in the input is unselected.
    this.highlightTarget($el);
  },

  highlightTarget($target) {
    this.currentTarget = $target;
    this.$el.find('.element-focus').removeClass('element-focus');
    this.currentTarget.addClass('element-focus');
  },

  initialize() {
    this.currencyIso3 = DefaultShopConfigurationModel.getCurrencyIso3();
  },

  drawerClicked: _.debounce(() => {
    ReceiptPrinterModel.openDrawer();
  }, 500),

  calculateTotal() {
    let superTotal = '0,00';
    this.ui.quantity.get().forEach((el) => {
      const $el = $(el);
      const quantityString = $el.val();
      const amount = $el.data('quantity');
      const quantity = parseInt(quantityString);
      let total = '0,00';
      if (!_.isNaN(quantity)) {
        total = Currency.Math.mul(amount, Currency.toCurrency(quantity));
        superTotal = Currency.Math.add(superTotal, total);
      }
    });
    return superTotal;
  },

  onRender() {
    const region = this.getRegion('keypad');
    const view = new KeypadView();
    region.show(view);
  },

  handleKeyPress(keyPressed) {
    if (this.currentTarget) {
      const value = parseInt(keyPressed);
      if (!_.isNaN(value)) {
        const currentVal = this.currentTarget.val();
        this.currentTarget.val(`${currentVal}${value}`);
        this.currentTarget.trigger('keyup');
      }
    }
  },

  handleConfirmation() {
    this.confirmAndGotoNextInput();
  },

  handleBackspace() {
    if (this.currentTarget) {
      let value = this.currentTarget.val();
      value = value.slice(0, -1);
      if (value.length <= 0) {
        value = 0;
      }
      this.currentTarget.val(value);
      this.currentTarget.trigger('keyup');
    }
  },

  handleCancel() {
    this.$el.find('.element-focus').removeClass('element-focus');
    if (this.currentTarget) {
      this.currentTarget.val(0);
      this.currentTarget.trigger('keyup');
    }
    this.currentTarget = null;
  },

  confirmAndGotoNextInput() {
    /** Getting the next targets and if it exists select it */
    const currentIndex = parseInt(this.currentTarget.data('index'));
    const nextTarget = this.$el.find(`[data-index="${currentIndex + 1}"]`);
    if (nextTarget.get(0)) {
      this.$el.find('.element-focus').removeClass('element-focus');
      this.currentTarget = null;
      this.highlightTarget(nextTarget);
    }
  },

  handleTab(event) {
    if (this.currentTarget) {
      /** Prevent tabbing into other parts of the UI */
      event.preventDefault();
      this.confirmAndGotoNextInput();
    }
  },

  handleTabShift(event) {
    if (this.currentTarget) {
      /** Prevent tabbing into other parts of the UI */
      event.preventDefault();

      /** Getting the previous targets and if it exists select it */
      const currentIndex = parseInt(this.currentTarget.data('index'));
      const nextTarget = this.$el.find(`[data-index="${currentIndex - 1}"]`);
      if (nextTarget.get(0)) {
        this.handleConfirmation();
        this.highlightTarget(nextTarget);
      }
    }
  },

  applyClicked() {
    const currentAmounts = [];

    this.ui.quantity.get().forEach((el) => {
      const $el = $(el);
      const quantityString = $el.val();
      const amount = $el.data('quantity');
      const quantity = parseInt(quantityString);
      currentAmounts[amount] = Currency.toCurrency(quantity);
    });

    this.currentTarget = null;
    this.closeDef.resolve({
      currentAmounts,
      total: this.calculateTotal(),
    });
    this.close();
  },

  quantityChanged(ev) {
    const $el = $(ev.currentTarget);
    let quantityString = $el.val();
    const amount = $el.data('quantity');
    if (quantityString.length <= 0) {
      quantityString = '0';
    }
    let quantity = parseInt(quantityString);
    let total = '0,00';
    if (!_.isNaN(quantity)) {
      if (quantity < 0) {
        quantity = 0;
      }
      $el.val(quantity);
      total = Currency.Math.mul(amount, Currency.toCurrency(quantity));

      const $total = this.$el.find(`[data-total="${amount}"]`);
      if ($total) {
        $total.html(Currency.format(this.currencyIso3, total, { space: '&nbsp;' }));
      }
    }
  },

  open(currentAmounts, def) {
    currentAmounts = currentAmounts || [];
    def = this.renderInFloatingRegion(def);

    const self = this;
    const keys = _.keys(currentAmounts);
    keys.forEach((amount) => {
      const quantity = currentAmounts[amount];
      if (quantity) {
        const $quantity = self.$el.find(`[data-quantity="${amount}"]`);
        if ($quantity) {
          $quantity.val(parseInt(quantity));
          $quantity.trigger('keyup');
        }
      }
    });

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/cashAmountPopup',
    });

    this.openPopup();

    return def;
  },

  onShow() {
    KeyboardModel.on('key:pressed', this.handleKeyPress, this);
    KeyboardModel.on('key:confirm', this.handleConfirmation, this);
    KeyboardModel.on('key:backspace', this.handleBackspace, this);
    KeyboardModel.on('key:cancel', this.handleCancel, this);
    KeyboardModel.on('key:tab', this.handleTab, this);
    KeyboardModel.on('key:tab_shift', this.handleTabShift, this);
  },

  onDestroy() {
    this.close();
    KeyboardModel.off('key:pressed', this.handleKeyPress, this);
    KeyboardModel.off('key:confirm', this.handleConfirmation, this);
    KeyboardModel.off('key:backspace', this.handleBackspace, this);
    KeyboardModel.off('key:cancel', this.handleCancel, this);
    KeyboardModel.off('key:tab', this.handleTab, this);
    KeyboardModel.off('key:tab_shift', this.handleTabShift, this);
  },

  getAmounts() {
    const amounts = [
      {
        amount: '0,01',
        clsName: '001',
      },
      {
        amount: '0,02',
        clsName: '002',
      },
      {
        amount: '0,05',
        clsName: '005',
      },
      {
        amount: '0,10',
        clsName: '01',
      },
      {
        amount: '0,20',
        clsName: '02',
      },
      {
        amount: '0,50',
        clsName: '05',
      },
      {
        amount: '1,00',
        clsName: '1',
      },
      {
        amount: '2,00',
        clsName: '2',
      },
      {
        amount: '5,00',
        clsName: '5',
      },
      {
        amount: '10,00',
        clsName: '10',
      },
      {
        amount: '20,00',
        clsName: '20',
      },
      {
        amount: '50,00',
        clsName: '50',
      },
      {
        amount: '100,00',
        clsName: '100',
      },
      {
        amount: '200,00',
        clsName: '200',
      },
      {
        amount: '500,00',
        clsName: '500',
      },
    ];

    return amounts.map((obj, index) => {
      obj.index = index;
      return obj;
    });
  },

  chunk(arr, len) {
    const chunks = [];
    let i = 0;
    const n = arr.length;

    while (i < n) {
      chunks.push(arr.slice(i, i += len));
    }

    return chunks;
  },

  serializeData() {
    const amounts = this.getAmounts();
    return {
      amounts: this.chunk(amounts, 8),
      currency_iso3: this.currencyIso3,
    };
  },

}));
