define([
  'jquery',
  'backbone',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/repairMoreDetailsPopup.hbs',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'toastr',

  'modules/admin/behaviors/loader',

  'modules/shop.cash-register-retail/views/popups/repairMoreDetailsPopup/collectionView',
  'upx.modules/ShopModule/collections/RepairQuestionAnswer',
], (
  $, Backbone, PopupView, Template, Locale, CashRegisterApi, Toastr,
  Loader,
  RepairQuestionAnswerCollectionView, RepairQuestionAnswerCollection,
) => PopupView.extend({
  template: Template,

  className: 'dialog',

  ui: {
    close: '[data-action="close"]',
    title: '[data-ui="title"]',
  },

  events: {
    'click @ui.close': 'closeClicked',
    'touchend @ui.close': 'closeClicked',
  },

  regions: {
    repairQuestionAnswers: '[data-region="repair-question-answers"]',
  },

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  initialize({ repairId }) {
    this.repairId = repairId;
  },

  renderRepairQuestionAnswersView(def) {
    const repairQuestionAnswerCollection = new RepairQuestionAnswerCollection();
    const params = {
      start: 0,
      limit: 100,
      sort: [{
        name: 'repair_question/sequence_order',
        dir: 'asc',
      }, {
        name: 'question_text',
        dir: 'asc',
      }],
      filters: [{
        name: 'repair_id__=',
        val: this.repairId,
      }],
    };

    repairQuestionAnswerCollection.fetchAll({ params }).then(() => {
      def.resolve();

      const region = this.getRegion('repairQuestionAnswers');
      region.show(new RepairQuestionAnswerCollectionView({
        collection: repairQuestionAnswerCollection,
      }));
    }, ({ error }) => {
      Toastr.error(error);
      def.reject();
    });
  },

  onRender() {
    const def = this.loader.startLoader();
    this.renderRepairQuestionAnswersView(def);
  },

  open(title, def) {
    def = this.renderInFloatingRegion(def);

    this.ui.title.text(title);

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/repairModeDetailsPopup',
      title,
    });

    this.openPopup();

    return def;
  },

}));
