define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/popups/openCCVPinTransactionPopup/openTransaction.hbs',
  'modules/common/components/locale',
  'toastr',

  'modules/admin/behaviors/loader',
  'modules/shop.cash-register-retail/components/cashRegisterApi',

  'modules/shop.cash-register-retail/models/settings/receiptPrinter',
  'modules/common/components/currency',
], (
  $, _, Backbone, Template, Locale, Toastr,
  Loader, CashRegisterApi,
  ReceiptPrinterModel, Currency,
) => Backbone.Marionette.LayoutView.extend({
  template: Template,

  events: {
    'click [data-action=recover]': 'recoverClicked',
  },

  initialize(options) {
    this.transaction = options.transaction;
  },

  recoverClicked() {
    const def = $.Deferred();

    $.when(
      ReceiptPrinterModel.isPrinterAvailable(),
    ).then((isPrinterAvailable) => {
      // Because CCV payments are required to have a receipt printer, we
      // need to make sure one is available.
      if (!isPrinterAvailable) {
        def.reject({
          error: Locale.translate('please_connect_a_receipt_printer_to_recover_this_pin_payment'),
        });
        return;
      }

      this.triggerMethod('layout:swap', 'recoveringTransaction');
    });

    def.then(() => {

    }, (e) => {
      const toastTitle = Locale.translate('error_while_recovering_pin_transaction');
      const toastMessage = e.error || Locale.translate('an_error_occurred');
      Toastr.error(toastMessage, toastTitle);
    });
  },

  serializeData() {
    const { currency, amount, startDate } = this.transaction;

    return {
      totalAmount: Currency.format(currency, amount),
      dateStarted: new Date(startDate),
    };
  },

}));
