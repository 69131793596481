define([
  'jquery',
  'underscore',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/messagePopup',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
], (
  $, _, PopupView, Template, Locale, CashRegisterApi,
) => PopupView.extend({
  template: Template,

  className: 'dialog dialog--message',

  ui: {
    close: '[data-action="close"]',
    message: '[data-ui="message"]',
    title: '[data-ui="title"]',
  },

  events: {
    'click @ui.close': 'closeClicked',
    'touchend @ui.close': 'closeClicked',
  },

  open(title, def, message) {
    if (title) {
      title = title.msg || title.message || title.error || title;
    }
    title = title || Locale.translate('error');
    def = this.renderInFloatingRegion(def);

    if (message) {
      this.ui.message.text(message);
      this.ui.message.show();
    }
    this.ui.title.text(title);

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/messagePopup',
      title,
      message,
    });

    this.openPopup();

    return def;
  },

  close: _.debounce(function () {
    PopupView.prototype.close.apply(this);
    if (!_.isString(this.ui.message)) {
      this.ui.message.text('');
      this.ui.message.hide();
    }
  }, 100),

}));
