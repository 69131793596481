define([
  'jquery',
  'underscore',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/productAddonPopup.hbs',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/cashRegisterApi',

  './productAddonPopup/collection',

  'modules/common/collections/DeepModelCollection',
], (
  $, _, PopupView, Template, Locale, CashRegisterApi,
  ProductAddonPopupCollectionView,
  DeepModelCollection,
) => PopupView.extend({
  template: Template,

  className: 'dialog dialog--product-addon',

  initialize() {
    this.selection = new DeepModelCollection();
  },

  regions: {
    region: '[data-region="region"]',
  },

  onRender() {
    this.renderRegion();
  },

  renderRegion() {
    const region = this.getRegion('region');
    const view = new ProductAddonPopupCollectionView({
      collection: this.collection,
      selection: this.selection,
    });
    region.show(view);
  },

  events: {
    'click [data-action="close"]': 'closeClicked',
    'touchend [data-action="close"]': 'closeClicked',

    'click [data-action="confirm"]': 'confirmClicked',
    'touchend [data-action="confirm"]': 'confirmClicked',
  },

  confirmClicked: _.debounce(function () {
    this.triggerMethod('sub_items:selected', this.getSubItems());
    this.close();
    // Timeout needed to let the popup close before destroying the view.
    // This caused some render issues.
    setTimeout(() => {
      this.destroy();
    });
  }, 100),

  getSubItems() {
    return this.selection.map((model) => ({
      ppu_wt: model.get('product_price.ppu_wt'),
      currency_iso3: model.get('product_price.currency_iso3'),
      quantity: '1',
      sku: model.get('sku'),
      name: model.get('title'),
      tax: model.get('product_price.tax'),
      shop_product_id: model.get('shop_product_id'),
    }));
  },

  open(def) {
    def = this.renderInFloatingRegion(def);

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/productAddonPopup',
    });

    // In the backend has_addon is sometimes true without the product having any addons below it
    if (this.collection.length > 0) {
      this.openPopup();
    } else {
      setTimeout(() => {
        this.confirmClicked();
      });
    }
    return def;
  },

}));
