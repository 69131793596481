define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/popups/calendarEventPopup/sum/view.hbs',
], (
  $, _, Backbone, Template,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'sum__item',

  events: {
    'click [data-action="time"]': 'timeClicked',
    'click [data-action="remove"]': 'removeClicked',
  },

  removeClicked() {
    this.model.collection.remove(this.model);
  },

  timeClicked() {
    this.triggerMethod('layout:swap', 'duration');
  },
}));
