define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/popups/calendarEventPopup/layout.hbs',

  'modules/shop.cash-register-retail/views/inputs/employeeSearchInput',
  'modules/shop.cash-register-retail/views/inputs/customerSearchInput',
  './dateTime',
  './length',
  './treatments/layout',
  './sum/collection',

  'modules/shop.cash-register-retail/collections/employee',
  'modules/shop.cash-register-retail/collections/employeeCalendar',

  'modules/admin/behaviors/loader',
  'modules/common/components/moment',
  'toastr',
  'modules/common/components/locale',
], (
  $, _, Backbone, Template,
  EmployeeSearchInput, CustomerSearchInput, DateTimeView, LengthView, TreatmentView, SumCollection,
  EmployeeCollection, EmployeeCalendarCollection,
  Loader, Moment, Toastr, Locale,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  initialize(options) {
    this.viewDeferred = options.viewDeferred;

    // Make sure the duration is correct
    this.model.calculateEndDate();
  },

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  regions: {
    employee: '[data-region=employee]',
    customer: '[data-region=customer]',
    dateTime: '[data-region=date-time]',
    length: '[data-region=length]',
    sum: '[data-region=sum]',
    treatments: '[data-region=treatments]',
  },

  onRender() {
    this.renderEmployee();
    this.renderCustomer();
    this.renderDateTime();
    this.renderLength();
    this.renderSum();
    this.renderTreatments();
  },

  renderEmployee() {
    // Get initial employee model;
    const data = {};
    const calendarModel = EmployeeCalendarCollection.get(this.model.get('item.calendar_id'));
    if (calendarModel) {
      const employeeModel = EmployeeCollection.findWhere({ 'group_member.relation_data_id': calendarModel.get('relation_data_id') });
      if (employeeModel) {
        data.initialEmployeeModel = employeeModel;
      }
    }

    // Render region
    const region = this.getRegion('employee');
    const view = new EmployeeSearchInput(data);
    region.show(view);
    view.on('employee:selected', (employeeModel) => {
      const CalendarModel = EmployeeCalendarCollection.findWhere({
        relation_data_id: employeeModel.get('group_member.relation_data_id'),
      });

      if (CalendarModel) {
        this.model.set({
          'item.calendar_id': CalendarModel.get('id'),
        });
      }
    });
  },

  renderCustomer() {
    const region = this.getRegion('customer');
    const view = new CustomerSearchInput({
      relation_data_id: this.model.get('customer_id'),
    });
    region.show(view);
    view.on('customer:selected', (customerModel) => {
      this.model.set('customer_id', customerModel.get('id'));
      this.model.set('item.title', customerModel.get('name'));
    });
  },

  renderDateTime() {
    const region = this.getRegion('dateTime');
    const view = new DateTimeView({
      model: this.model,
    });
    region.show(view);
  },

  renderLength() {
    const region = this.getRegion('length');
    const view = new LengthView({
      model: this.model,
    });
    region.show(view);
  },

  renderSum() {
    const region = this.getRegion('sum');
    const view = new SumCollection({
      model: this.model,
    });
    region.show(view);
  },

  renderTreatments() {
    const region = this.getRegion('treatments');
    const view = new TreatmentView({
      appointmentModel: this.model,
    });
    region.show(view);
  },

  events: {
    'click [data-action="save"]': 'saveClicked',
    'click [data-action="cancel"]': 'cancelClicked',
    'click [data-action="delete"]': 'deleteClicked',
    'click [data-action="pay"]': 'payClicked',
  },

  isValid() {
    // Check appointment length
    const startDate = new Moment(this.model.get('item.date_start'));
    const endDate = new Moment(this.model.get('item.date_end'));
    if (endDate.diff(startDate, 'minutes') <= 0) {
      Toastr.error(Locale.translate('add_at_least_one_treatment'));
      return false;
    }

    // Check employee selected
    if (!this.model.has('item.calendar_id')) {
      Toastr.error(Locale.translate('select_an_employee'));
      return false;
    }

    // Check customer selected
    if (!this.model.has('customer_id')) {
      Toastr.error(Locale.translate('select_a_customer'));
      return false;
    }

    // Check order items being edited
    const orderItems = this.model.get('order.order_items') || [];
    let orderItemEdit = false;
    orderItems.forEach((item) => {
      if (!orderItemEdit) {
        orderItemEdit = !!item.edit;
      }
    });
    if (orderItemEdit) {
      Toastr.error(Locale.translate('one_or_more_of_the_treatments_are_being_edited_dot'));
      return false;
    }

    return true;
  },

  prepareModel() {
    // correct order_items
    const newOrderItems = [];
    const orderItems = this.model.get('order.order_items') || [];
    const orderItemFields = _.keys({
      id: '1',
      sku: '1',
      ppu: '1',
      ppu_wt: '1',
      before_discount_ppu: '1',
      before_discount_ppu_wt: '1',
      quantity: '1',
      name: '1',
      description: '1',
      extra: '1',
      shop_product_id: '1',
      attribute_option_ids: '1',
      shipping_method_id: '1',
      payment_provider_method_id: '1',
      is_recurring: '1',
      is_shipping: '1',
      is_payment: '1',
      is_discount: '1',
      tax_rate_id: '1',
    });

    orderItems.forEach((item) => {
      const newItem = {};
      orderItemFields.forEach((field) => {
        if (field in item) {
          newItem[field] = item[field];
        }
      });
      newOrderItems.push(newItem);
    });

    this.model.unset('order.order_items', { silent: true });
    this.model.set('order.order_items', newOrderItems);

    // Set description
    const name = _.pluck(newOrderItems, 'name');
    this.model.set('item.description', name.join(', '));
  },

  saveClicked() {
    this.prepareModel();
    if (this.isValid()) {
      const def = this.loader.startLoader('layout');

      def.then((id) => {
        if (id) this.model.set('item_id', id);

        this.viewDeferred.resolve({
          savedModel: this.model,
        });
      }, (resp) => {
        if (resp && 'error' in resp) {
          Toastr.error(resp.error);
        }
      });

      this.model.save()
        .then(def.resolve, def.reject);
    }
  },

  deleteClicked() {
    this.viewDeferred.resolve({
      deletedModel: this.model,
    });
  },

  payClicked() {
    this.viewDeferred.resolve({
      payedModel: this.model,
    });
  },

  cancelClicked() {
    this.viewDeferred.reject();
  },

}));
