define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/views/swappableWithLoading',

  './layout',
], (
  $, _, Backbone, SwappableView,
  LayoutView,
) => SwappableView.extend({

  initialize(options) {
    SwappableView.prototype.initialize.call(this, options);

    this.setMainView(LayoutView, {
      model: options.model,
      viewDeferred: options.viewDeferred,
    });
  },

  loadSharedData(dataStorage) {
    const def = new $.Deferred();

    if (this.model.has('item_id')) {
      this.model.fetch()
        .then(def.resolve, def.reject);
    } else {
      def.resolve();
    }

    return def;
  },

}));
