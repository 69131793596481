define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/popups/configurableSelectionPopup/listItem',
  'modules/upx/components/upx',

  'modules/common/components/locale',

  'modules/shop.cash-register-retail/components/productCache',
  'modules/admin/behaviors/loader',
  'modules/shop.cash-register-retail/components/productStock',
], (
  $, _, Backbone, Template, Upx, Locale,
  ProductCacheComponent, Loader, ProductStock,

) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  tagName: 'tr',

  events: {
    'click [data-action="add"]': 'addClicked',
    'touchend [data-action="add"]': 'addClicked',
  },

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  initialize({ resultDef, showAttributeIds }) {
    this.resultDef = resultDef;
    this.showAttributeIds = showAttributeIds;
  },

  addClicked: _.debounce(function () {
    this.resultDef.resolve(this.model);
    // update the cache for added product
    ProductCacheComponent.storeShopProduct(this.model);
  }, 100),

  getProductAttributes() {
    const wantedAttributes = [];

    if (this.model.has('flat_product.content_vars')) {
      const contentVars = this.model.get('flat_product.content_vars');
      contentVars.forEach((object) => {
        if (_.contains(this.showAttributeIds, object.attribute_id)) {
          const model = new Backbone.Model(object);
          let attributeName = model.get('name');
          let attributeValue = model.get('value');
          if (model.has('label')) {
            attributeName = model.get('label');
          }
          if (model.has('value_label')) {
            attributeValue = model.get('value_label');
          }
          wantedAttributes.push({
            name: attributeName,
            value: attributeValue,
          });
        }
      });
    }

    return wantedAttributes;
  },

  serializeData() {
    const data = this.model.toJSON();
    data.product_attributes = this.getProductAttributes();
    data.useStock = ProductStock.useStockValues();
    if (data.useStock) {
      data.stockAmount = ProductStock.getStockFromFlatProduct(this.model);
      data.stockClass = ProductStock.getStockTextClassFromFlatProduct(this.model);
    }
    return data;
  },

}));
