define([
  'jquery',
  'underscore',
  'backbone',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/templates/popups/loyaltyPointsPaymentPopup.hbs',

  'modules/common/components/currency',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/upx/components/upx',
  'modules/shop.cash-register-retail/components/toaster',

  './loyaltyPointsPaymentPopup/balanceRemaining',
  'modules/shop.cash-register-retail/views/keypads/main',
  'modules/shop.cash-register-retail/views/inputs/currencyInput',

  'modules/admin/behaviors/loader',
  'modules/shop.cash-register-retail/models/settings/paymentMethods',
  'modules/shop.cash-register-retail/models/upx/LoyaltyProgram',
  'modules/shop.cash-register-retail/models/selectedCustomer',
  'modules/shop.cash-register-retail/models/upx/DefaultShopConfiguration',
], (
  $, _, Backbone, PopupView, Template,
  Currency, Locale, CashRegisterApi, Upx, Toaster,
  BalanceRemainingView, KeypadsView, PriceInputView,
  Loader, PaymentMethods, LoyaltyProgramModel, SelectedCustomerModel, DefaultShopConfiguration,
) => PopupView.extend({
  template: Template,

  className: 'dialog dialog--loyalty-points-payment',

  ui: {
    close: '[data-action="close"]',
    apply: '[data-action="apply"]',
    title: '[data-ui="title"]',
  },

  events: {
    'click @ui.close': 'closeClicked',
    'touchend @ui.close': 'closeClicked',

    'click @ui.apply': 'applyClicked',
    'touchend @ui.apply': 'applyClicked',
  },

  regions: {
    input: '[data-region="input"]',
    balance: '[data-region="balance"]',
    keys: '[data-region="keys"]',
  },

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  initialize(options) {
    PopupView.prototype.initialize.apply(this, options);
    const { loyaltyProgramModel, customerModel, paymentMethodCollection } = options;

    this.paymentMethodCollection = paymentMethodCollection;
    this.loyaltyProgramModel = loyaltyProgramModel || LoyaltyProgramModel;
    this.customerModel = customerModel || SelectedCustomerModel;
    this.exchangeValue = Currency.toCurrency(this.getInitialExchangeValueAsFloat());
  },

  onRender() {
    this.renderInput();
    this.renderBalance(this.getInitialExchangeValueAsFloat());
    this.renderKeys();
  },

  renderInput() {
    const initialExchangeValue = this.getInitialExchangeValueAsFloat();
    this.inputView = new PriceInputView({
      autoSelect: true,
      startValue: initialExchangeValue,
      maxValue: initialExchangeValue,
    });

    this.inputView.on('view:confirm', this.setExchangeValue, this);
    this.getRegion('input').show(this.inputView);
  },

  getInitialExchangeValueAsFloat() {
    let initialExchangeValue = this.getLoyaltyBalanceAsFloat();
    // Loyalty points payment should only set left to pay if there are other payments
    if (parseFloat(this.paymentMethodCollection.getLeftToPayWt(
      (model) => model.get('id') !== this.paymentMethodCollection.LOYALTY_POINTS_METHOD,
    )) < parseFloat(initialExchangeValue)) {
      initialExchangeValue = parseFloat(
        this.paymentMethodCollection.getLeftToPayWt((model) => model.get('id') !== this.paymentMethodCollection.LOYALTY_POINTS_METHOD),
      );
    }

    if (Number.isNaN(initialExchangeValue)) {
      return 0.00;
    }

    return initialExchangeValue;
  },

  setExchangeValue(value) {
    this.exchangeValue = value;
    this.renderBalance(value);
  },

  renderBalance(exchangeValue) {
    const model = new Backbone.Model();
    model.set({
      exchangeValue: Currency.toCurrency(exchangeValue),
      currentValue: this.getLoyaltyBalanceInCurrency(),
    });
    this.getRegion('balance').show(new BalanceRemainingView({
      model,
    }));
  },

  renderKeys() {
    this.getRegion('keys').show(new KeypadsView());
  },

  showLoader() {
    this.loaderDef = this.loader.startLoader();
  },

  hideLoader() {
    if (this.loaderDef) this.loaderDef.resolve();
    this.loaderDef = null;
  },

  open(def) {
    def = this.renderInFloatingRegion(def);

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/popups/loyaltyPointsPaymentPopup',
    });

    this.openPopup();

    return def;
  },

  applyClicked: _.debounce(function () {
    this.closeDef.resolve({
      maxAmount: this.getLoyaltyBalanceAsFloat(),
      ppuWt: this.exchangeValue,
      loyaltyPointsSpent: this.loyaltyProgramModel.getSpentPointsValueOfFloat(
        parseFloat(this.exchangeValue),
      ),
      loyaltyPointsOriginalBalance: this.getLoyaltyBalanceInPoints(),
    });
    this.close();
  }, 100),

  getLoyaltyBalanceInPoints() {
    return this.customerModel.get('loyalty_customer.point_total') || 0;
  },

  getLoyaltyBalanceInCurrency() {
    return this.loyaltyProgramModel.getSpentCurrencyValueOfPoints(
      this.getLoyaltyBalanceInPoints(),
    );
  },

  getLoyaltyBalanceAsFloat() {
    return Currency.currencyToFloat(this.getLoyaltyBalanceInCurrency());
  },

  buildExamples() {
    const centsValues = [
      100, // 100 cents = 1 euro
      1000, // 1000 cents = 10 euro
      5000, // 5000 cents = 50 euro
    ];
    const pointsPerCent = this.loyaltyProgramModel.get('points_per_cent_with_payment') || 0;

    if (pointsPerCent === 0) {
      return null;
    }

    return _.map(centsValues, (centsValue) => {
      const currencyValue = Currency.Math.mul(
        Currency.toCurrency(centsValue),
        Currency.toCurrency(0.01),
      );
      const totalPoints = Currency.Math.mul(
        Currency.toCurrency(centsValue),
        Currency.toCurrency(pointsPerCent),
      );

      return {
        points: Currency.Math.integralPart(totalPoints),
        currencyValue: Currency.format(DefaultShopConfiguration.getCurrencyIso3(), currencyValue),
      };
    });
  },

  serializeData() {
    const examples = this.buildExamples();
    const pointsBalance = this.getLoyaltyBalanceInPoints();
    return {
      pointsBalance: Currency.formatAmount('EUR', pointsBalance),
      currentValue: this.getLoyaltyBalanceInCurrency(),
      currentExchangeRate: Locale.translate('{points}_point_s_is_euro_001', {
        points: this.loyaltyProgramModel.get('points_per_cent_with_payment') || 0,
      }),
      usageExplanation: this.loyaltyProgramModel.get('usage_explanation') || '-',
      examples,
      hasExamples: examples !== null,
    };
  },

}));
